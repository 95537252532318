<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="740px"
      height="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        编辑小组
      </div>
      <div
        v-if="groupItem"
        class="content flex-column flex-center flex-column-center"
      >
        <div class="group-content flex-column">
          <div class="flex flex-center">
            <div class="group-name div-center size24">
              <input
                v-model="form.groupName"
                placeholder="请输入小组名称"
                class="name-input"
              />
            </div>
          </div>
          <div class="student-list flex top24">
            <student-item
              v-for="(item,index) in groupItem.studentEvaluationInfoVOList"
              :item="item"
              :key="index"
              :index="index"
              @select="selectGroupItemStudent(item,$event)"
            ></student-item>
          </div>
          <div class="">
            <div class="line"></div>
          </div>
          <div class="top20">
            <div v-if="unJoinGroupStudentList.length > 0">
              <div class="size16 color10 div-center">未加入任何小组</div>
              <div class="student-list top24">
                <student-item
                  v-for="(item,index) in unJoinGroupStudentList"
                  :item="item"
                  :key="index"
                  :index="index"
                  @select="selectStudent"
                ></student-item>
              </div>
            </div>
          </div>
          <div
            class="top20"
            v-if="joinGroupList"
          >
            <div
              v-for="(item,index) in joinGroupList"
              :key="index"
            >
              <div>
                <div class="size16 color10 div-center">{{item.groupName}} </div>
                <div class="student-list flex top24">
                  <student-item
                    v-for="(item2,index2) in item.studentEvaluationInfoVOList"
                    :item="item2"
                    :key="index2"
                    :index="index2"
                    @select="selectGroupStudent(item2,item.studentEvaluationInfoVOList,$event)"
                  ></student-item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom flex flex-column-center flex-center">
          <a-popconfirm
            title="点击取消将不保存任何小组信息，确认取消并退出编辑小组吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="close"
          >
            <my-button size="small3">取消</my-button>
          </a-popconfirm>

          <a-popconfirm
            :title="`【${form.groupName}】组的小组信息将发生变化，是否继续？`"
            ok-text="确定"
            cancel-text="取消"
            @confirm="editHandle"
          >
            <my-button
              size="small3"
              class="left20"
            >保存小组</my-button>
          </a-popconfirm>

          <a-popconfirm
            title="解散小组后，该小组整组已获得评价数据清零。
已发放至各成员的评价不清空。"
            ok-text="确定"
            cancel-text="取消"
            @confirm="delHandle"
          >
            <div class="dissolve size16 color5">解散小组</div>
          </a-popconfirm>

        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import MyButton from '@/components/mybutton/MyButton.vue'
import StudentItem from '../class/studentItem.vue'
import { getUnclassifiedList, delGroup, editGroup } from '@/api/home/group.js'
import { getAvatar } from '@/utils/random.js'
export default {
  components: { MyButton, StudentItem },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      userInfo: this.$store.getters.userInfo,
      form: {
        groupId: '',
        groupName: '',
        studentIds: [],
        classId: this.$store.getters.classId,
      },
      unJoinGroupStudentList: [],
      groupItem: '',
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () { },
    },
    joinGroupList () {
      return this.list.filter((item) => item.groupName != this.item.groupName)
    },
  },
  watch: {
    show (val) {
      if (val) {
        this.form.groupName = this.item.groupName
        this.form.groupId = this.item.groupId
        this.getGroupItem()
        this.getData()
      }
    },
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
    },
    getGroupItem () {
      this.groupItem = this.item
      this.groupItem.studentEvaluationInfoVOList.map((item) => {
        item.checked = true
        return item
      })
    },
    getData () {
      getUnclassifiedList({
        classId: this.userInfo.classId,
      }).then((res) => {
        this.unJoinGroupStudentList = res
        this.unJoinGroupStudentList.map((item) => {
          item.avatar = getAvatar(item)
          item.checked = false
          return item
        })
      })
    },
    selectStudent (index) {
      this.$forceUpdate()
      this.unJoinGroupStudentList[index].checked =
        !this.unJoinGroupStudentList[index].checked
    },
    selectGroupItemStudent (item, index) {
      let value = JSON.parse(JSON.stringify(item))
      value.checked = !value.checked
      this.groupItem.studentEvaluationInfoVOList[index] = value
      this.$forceUpdate()
    },
    selectGroupStudent (item, list, index) {
      let value = JSON.parse(JSON.stringify(item))
      value.checked = !value.checked
      list[index] = value
      this.$forceUpdate()
    },
    editHandle () {
      let [
        selectedUnJoinGroupStudentList,
        selectJoinGroupListStudenList,
        selectGroupListStudentList,
      ] = [
          this.selectedUnJoinGroupStudent(),
          this.selectJoinGroupListStudent(),
          this.selectGroupListStudent(),
        ]
      this.form.studentIds = [
        ...selectedUnJoinGroupStudentList,
        ...selectJoinGroupListStudenList,
        ...selectGroupListStudentList,
      ]
      if (!this.form.groupName) {
        return this.$message.error('请输入小组名称')
      }
      if (this.form.studentIds.length == 0) {
        return this.$message.error('请选择学生')
      }
      editGroup(this.form).then(() => {
        setTimeout(() => {
          this.$message.success('保存小组成功')
          this.close()
          this.$emit('refresh')
        }, 800)
      })
    },
    delHandle () {
      delGroup(this.form).then(() => {
        setTimeout(() => {
          this.$message.success('解散小组成功')
          this.close()
          this.$emit('refresh')
        }, 800)
      })
    },
    selectedUnJoinGroupStudent () {
      const list = this.unJoinGroupStudentList.filter((item) => item.checked)
      return list.map((item) => item.studentId)
    },
    selectJoinGroupListStudent () {
      let list = []
      this.joinGroupList.map((item) => {
        item.studentEvaluationInfoVOList.map((item2) => {
          if (item2.checked) {
            list.push(item2.studentId)
          }
        })
      })
      return list
    },
    selectGroupListStudent () {
      let list = []
      if (this.groupItem) {
        console.log(this.groupItem)
        list = this.groupItem.studentEvaluationInfoVOList.filter(
          (item) => item.checked
        )
        return list.map((item) => item.studentId)
      }
      return list
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  padding-top: 20px;
  padding-bottom: 80px;
  .group-content {
    width: 100%;
    height: 450px;
    overflow: auto;
    padding-left: 60px;
    padding-right: 60px;
    .group-name {
      width: 600px;
      height: 52px;
      background: rgba(255, 206, 46, 0.3);
      border-radius: 20px;
      line-height: 52px;
    }
  }
  .student-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 120px);
    justify-content: center;
  }
  .dissolve {
    position: absolute;
    bottom: 0px;
    right: 20px;
    padding: 20px;
  }
  .dissolve:hover {
    cursor: pointer;
  }
}

.name-input {
  background-color: transparent;
  text-align: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.line {
  height: 2px;
  background: rgba(255, 206, 46, 0.3);
  border-radius: 20px;
}
</style>
