<template>
  <div>
    <div
      @click="select()"
      class="item hover flex"
    >
      <img
        v-if="selected"
        src="@images/account/icon_claass_proceed.png"
        class="status"
      >
      <class-number
        :name="item.className.substr(0,1)"
        :index="index"
      ></class-number>
      <div class="class-info left20">
        <div class="size32 color5">{{item.gradeName}}{{item.className}}</div>
        <div class="size16 top6">共有 {{item.studentNums}}名学生</div>
      </div>
    </div>
  </div>
</template>
<script>
import classNumber from '../../../components/class/classNumber.vue'
export default {
  components: { classNumber },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    select () {
      this.$emit('click', this.index)
    },
  },
}
</script>
<style lang="less" scoped>
.item {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin: 16px;
  align-items: center;
  .class-info {
    width: 140px;
  }

  .status {
    width: 120px;
    position: absolute;
    top: 0px;
  }
}
</style>
