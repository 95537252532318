<template>
  <div>
    <div class="content flex flex-center flex-column-center size120 color10">
      <div class="pad-top">{{ currentHours }}</div>
      <div class="padding20 pad-top"> :</div>
      <div class="pad-top">{{ currenMinutes }}</div>
      <div class="padding20 pad-top"> :</div>
      <div class="pad-top">{{ currenSeconds }}</div>
    </div>
    <div class="bottom flex flex-column-center flex-center">
      <close
        @click="close()"
        class="close"
      ></close>
      <div v-if="!isCount">
        <my-button
          size="big"
          @click="start()"
        >开始计时
        </my-button>
      </div>
      <div
        v-else
        class="flex"
      >
        <my-button
          size="small3"
          class="left40"
          color="pink"
          @click="restart()"
        >重新开始
        </my-button>
        <my-button
          v-if="timer"
          size="small3"
          class="left40"
          @click="stop()"
        >暂停计时
        </my-button>
        <my-button
          v-else
          size="small3"
          class="left40"
          @click="count()"
        >继续计时
        </my-button>
      </div>
    </div>
  </div>
</template>
<script>
import close from '@/components/close/Index.vue'
import MyButton from '@/components/mybutton/MyButton.vue'

export default {
  props: {},
  components: { MyButton, close },
  computed: {
    currentHours () {
      return this.hours < 10 ? `0${this.hours}` : this.hours
    },
    currenMinutes () {
      return this.minutes < 10 ? `0${this.minutes}` : this.minutes
    },
    currenSeconds () {
      return this.seconds < 10 ? `0${this.seconds}` : this.seconds
    },
  },
  data () {
    return {
      isCount: false,
      countTime: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timer: null
    }
  },
  methods: {
    init () {
      this.stop()
      this.countTime = 0
      this.hours = 0
      this.minutes = 0
      this.seconds = 0
    },
    start () {
      this.isCount = true
      this.count()
    },
    count () {
      this.timer = setInterval(() => {
        this.countTime += 1
        if (this.seconds < 59) {
          this.seconds += 1
        } else if (this.seconds === 59) {
          this.seconds = 0
          if (this.minutes < 59) {
            this.minutes += 1
          } else if (this.minutes === 59) {
            this.minutes = 0
            this.hours += 1
          }
        }
      }, 1000)
    },
    stop () {
      clearInterval(this.timer)
      this.timer = null
    },
    restart () {
      this.init()
      this.count()
    },
    close () {
      this.$emit("close");
    }
  }
}
</script>
<style lang="less" scoped>
.close {
  position: absolute;
  left: 30px;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 70px;
  background: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  max-height: 80px;
}

.content {
  width: 700px;
  height: 260px;
  background: rgba(255, 206, 46, 0.3);
  border-radius: 20px;
}

.padding20 {
  padding: 0 20px;
}

.pad-top {
  padding-top: -15px;
}
</style>