<template>
  <div class="group-item">
    <div
      @click="selected(item)"
      class="hover item"
    >
      <div class="bg"></div>
      <div class="content">
        <div class="flex item-content">
          <group-image :item="item"></group-image>
          <div class="right flex1 left12">
            <div class="group-name top6 size28  color7"><span class="bold">{{ item.groupName }}</span> <span class="size14">({{ item.studentEvaluationInfoVOList.length || 0 }}人)</span></div>
            <div class="top4 count flex size20 color7">
              <div class="flex1 bg1 div-center">{{ item.groupGoodNums ? '+' + item.groupGoodNums : 0 }}</div>
              <div class="flex1 bg2 div-center">{{ item.groupBadNums ? '-' + item.groupBadNums : 0 }}</div>
            </div>
            <div class="star-group-wrap">
              <div
                :key="index"
                :style="`max-width: 25%;width:`+ 100/item.groupGoodNums + `%;`"
                v-for="(i,index) in item.groupGoodNums"
              >
                <img
                  src="@images/home/icon_star_yellow.png"
                  alt="star"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="top12 name-list size16 flex"
          v-if="fold"
        >
          <div
            class="name-item"
            v-for="(item2,index2) in item.studentEvaluationInfoVOList"
            :key="index2"
          >{{ item2.studentName }}
          </div>
        </div>
        <div
          class="shrink-btn flex"
          @click.stop="change()"
        >
          <div class="btn flex flex-center">
            <a-icon
              v-if="fold"
              type="up"
              :style="{'color':'rgba(0,0,0,0.5)'}"
            />
            <a-icon
              v-else
              type="down"
              :style="{'color':'rgba(0,0,0,0.5)'}"
            />
          </div>
        </div>
      </div>
      <div
        @click.stop="edit(item)"
        class="eidt"
        v-if="type == 2"
      >
        <img
          src="@images/home/icon_edit.png"
          class="icon_edit"
        >
      </div>
      <div
        v-if="checked && menuIndex == 3"
        class="selected flex flex-center flex-column-center"
      >
        <img
          src="@images/home/icon_selected.png"
          class="icon_selected"
        >
      </div>
    </div>
  </div>
</template>
<script>
import groupImage from '@/components/group/GroupImage.vue'
export default {
  components: {
    groupImage
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    type: {
      type: Number,
      default: 1, // 1 正常 2编辑组
    },
    menuIndex: {
      type: Number,
      default: 2,
    },
  },
  data () {
    return {
      checked: false,
      fold: false
    }
  },
  methods: {
    selected (item) {
      if (this.type == 1) {
        this.checked = !this.checked
        this.$emit('selected', item)
      }
    },
    change () {
      this.fold = !this.fold
    },
    edit (item) {
      this.$emit('edit', item)
    },
  },
}
</script>
<style lang="less" scoped>
.group-item {
  display: flex;
  justify-content: center;
}
.item {
  width: 360px;
  background: #ffffff;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 44px;
  border: 4px solid #ffffff;
  margin-bottom: 34px;
  padding: 20px 24px;
  position: relative;

  .bg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 78px;
    background: #fff0c0;
    border-radius: 40px 40px 0 0;
    box-shadow: inset 0px -6px 10px 0px rgba(0, 0, 0, 0.04);
  }

  .content {
    position: relative;

    .item-content {
      .avatar {
        width: 120px;
        height: 120px;
        border-radius: 44px;
        opacity: 0.8;
        border: 2px solid #ffffff;
      }

      .count {
        width: 124px;
        height: 36px;
        background: #ffeecd;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
        border-radius: 60px;
        border: 2px solid #ffffff;
        overflow: hidden;
        line-height: 32px;

        .bg1 {
          color: rgba(153, 95, 0, 1) !important;
          background-color: rgba(255, 206, 46, 0.7);
        }

        .bg2 {
          color: rgba(226, 101, 98, 1) !important;
          background-color: rgba(255, 220, 211, 1);
        }
      }

      .image-list {
        position: relative;

        .img {
          width: 38px;
          height: 36px;
        }

        .img2 {
          margin-left: -17px;
        }
      }
    }

    .shrink-btn {
      width: 100%;
      position: absolute;
      bottom: -24px;
      justify-content: center;

      .btn {
        width: 65px;
        height: 20px;
        background: rgba(255, 206, 46, 0.3);
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
        border-radius: 100px 100px 0px 0px;
        z-index: 9;
      }
    }

    .shrink-btn:hover {
      cursor: pointer;
    }
  }

  .name-list {
    flex-wrap: wrap;

    .name-item {
      min-width: 64px;
      height: 30px;
      text-align: center;
      background: #ffeecd;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border-radius: 22px;
      border: 2px solid #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      margin-right: 12px;
      margin-bottom: 12px;
      line-height: 26px;
    }
  }

  .eidt {
    position: absolute;
    top: 4px;
    right: 3px;

    & img {
      width: 36px;
    }
  }

  .eidt:hover {
    cursor: pointer;
  }

  .selected {
    position: absolute;
    left: -6px;
    top: -6px;
    width: 80px;
    height: 80px;
    background: #ff8c29;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: 22px;
    border: 4px solid #ffffff;

    .icon_selected {
      width: 61px;
    }
  }
  .star-group-wrap {
    display: flex;
    flex-flow: nowrap;
    width: 120px;
  }
}
</style>