<template>
  <div>
    <img
      v-if="type===1"
      width="800"
      src="@/assets/images/evaluate/card.gif"
    />
    <img
      v-else
      width="800"
      src="@/assets/images/evaluate/bad.gif"
    />
    <audio
      src="@/assets/audio/evaluate/good.mp3"
      v-if="type===1"
      autoplay="autoplay"
      ref="evaluate"
    />
    <audio
      src="@/assets/audio/evaluate/bad.mp3"
      v-else
      autoplay="autoplay"
      ref="evaluate"
    />
  </div>
</template>
<script>

export default {
  name: 'Evaluate',
  props: {
    type: { // 1 好习惯
      type: Number,
      default () {
        return 1
      }
    }
  },
  data () {
    return {}
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
</style>