<template>
  <div>
    <nav-bar-layout v-if="!nav.isHide"></nav-bar-layout>
    <router-view :class="{'body':!nav.isHide}"></router-view>
  </div>
</template>

<script>
import NavBarLayout from '@/components/layouts/NavBarLayout'
export default {
  components: {
    NavBarLayout
  },
  data () {
    return {
      nav: this.$store.getters.navbar,
    }
  }
}
</script>

<style lang="less" scoped>
.body {
  min-height: calc(100vh - 46px);
}
</style>

