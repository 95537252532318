export function courseCountDown(that) {
    clearInterval(that.timer);
    that.timer = null;

    let time = that.$store.getters.classEndTimestamp;
    let remindTime = time - 5 * 1000 * 60;
    if (time > Date.now()) {
        that.timer = setInterval(() => {
            if (Date.now() >= remindTime) {
                console.log("时间是", Date.now());
                that.shoFinishRemind = true;
                clearInterval(that.timer);
                that.timer = null;
            }
        }, 1000);
    }
}
