<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="740px"
      height="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        <div>积分赛</div>
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <div class="race-content flex-column">
          <div
            class="list"
            v-if="studentData.length > 0"
          >
            <div
              class="item"
              v-for="(item,index) in studentData"
              :key="index"
            >
              <div class="avatar-img">
                <img :src="item.avatar">
              </div>
              <div class="score">
                {{item.score}}
              </div>
              <div class="name size16 color10">{{item.studentName}}</div>
              <div
                class="animation awarded"
                v-if="showAwardedAnimation && currentIndex == index"
              >+1</div>
              <div
                class="animation deduction"
                v-if="showDeductionAnimation && currentIndex == index"
              >-1</div>
              <div class="action flex">
                <div
                  @click="onMark(item,-1,index)"
                  class="action-item hover flex flex-center flex-column-center"
                >
                  <img src="@images/home/icon_negative.png">
                </div>
                <div
                  @click="onMark(item,1,index)"
                  class="action-item hover left12 flex flex-center flex-column-center"
                >
                  <img src="@images/home/icon_positive.png">
                </div>
              </div>
            </div>
          </div>
          <div
            class="list"
            v-else
          >
            <div
              class="item"
              v-for="(item,index) in groupData"
              :key="index"
            >
              <div class="avatar-img flex flex-center flex-column-center">
                <group-image :item="item"></group-image>
              </div>
              <div class="score">
                {{item.score}}
              </div>
              <div class="name size16 color10">{{item.groupName}}</div>
              <div
                class="animation awarded"
                v-if="showAwardedAnimation && currentIndex == index"
              >+1</div>
              <div
                class="animation deduction"
                v-if="showDeductionAnimation && currentIndex == index"
              >-1</div>
              <div class="action flex">
                <div
                  @click="onMark(item,-1,index)"
                  class="action-item hover flex flex-center flex-column-center"
                >
                  <img src="@images/home/icon_negative.png">

                </div>
                <div
                  @click="onMark(item,1,index)"
                  class="action-item hover left12 flex flex-center flex-column-center"
                >
                  <img src="@images/home/icon_positive.png">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom flex flex-column-center flex-center">
          <close
            @click="close()"
            class="close"
          ></close>
          <div class="submit flex flex-center">
            <my-button
              size="big"
              @click="createResult"
            >生成比赛结果</my-button>
          </div>
        </div>
      </div>
    </a-modal>
    <result-modal
      v-model="showResult"
      :studentList="studentResult"
      :groupList="groupResult"
      @evaluate="onEvaluate"
    ></result-modal>
    <evaluate-student-modal
      :studentList="selectedStudent"
      :groupsList="selectedGroup"
      v-model="showEvaluate"
      @refresh="refresh()"
    ></evaluate-student-modal>
  </div>
</template>
<script>
import close from '@/components/close/Index.vue'
import groupImage from '@/components/group/GroupImage.vue'
import MyButton from '@/components/mybutton/MyButton.vue'
import resultModal from '@/view/home/components/race/resultModal'
import EvaluateStudentModal from '../evaluate/evaluateStudentModal.vue'

export default {
  components: { close, groupImage, MyButton, resultModal, EvaluateStudentModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    studentList: {
      type: Array,
      default: null
    },
    groupList: {
      type: Array,
      default: null
    }
  },
  watch: {
    studentList (val) {
      val.map(item => {
        item.score = 0
        item.rank = 0
        item.evaluate = false
        return item
      })
      this.studentData = val
    },
    groupList (val) {
      val.map(item => {
        item.score = 0
        item.rank = 0
        item.evaluate = false
        return item
      })
      this.groupData = val
    }
  },
  data () {
    return {
      studentData: [],
      groupData: [],
      showAwardedAnimation: false,
      showDeductionAnimation: false,
      currentIndex: "",
      allowClick: true,
      showResult: false,
      studentResult: [],
      groupResult: [],
      showEvaluate: false,
      selectedStudent: [],
      selectedGroup: []
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () {
      },
    },
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
      this.$emit('close')
    },
    onMark (item, score, index) {
      if (this.allowClick) {
        this.$forceUpdate()
        this.currentIndex = index
        item.score += score
        if (score > 0) {
          this.showAwardedAnimation = true;
          this.allowClick = false
          setTimeout(() => {
            this.showAwardedAnimation = false;
            this.allowClick = true
          }, 500);
        } else {
          this.showDeductionAnimation = true;
          this.allowClick = false
          setTimeout(() => {
            this.showDeductionAnimation = false;
            this.allowClick = true
          }, 500);
        }
      }
    },
    createResult () {
      this.close()
      this.studentResult = [...this.studentData]
      this.groupResult = [...this.groupData]
      this.showResult = true;
    },
    refresh () {
      setTimeout(() => {
        this.$emit('refresh')
      }, 100)
      this.close()
    },
    onEvaluate (studentList, groupList) {
      console.log(1111, studentList)
      console.log(222, groupList)
      this.selectedStudent = studentList.filter(item => item.evaluate)
      this.selectedGroup = groupList.filter(item => item.evaluate)

      this.showEvaluate = true
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  position: relative;
  .add-text {
    position: absolute;
    top: 0px;
    right: 50px;
  }
}

.content {
  position: relative;
  padding-bottom: 80px;

  .race-content {
    width: 100%;
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 32px 0px;

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        position: relative;
        width: 164px;
        background: rgba(255, 206, 46, 0.2);
        padding: 24px 0;
        border-radius: 20px;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 6px;
        margin-left: 6px;

        .avatar-img {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          background: rgba(223, 233, 255, 1);
          border: 2px solid #ffffff;
          overflow: hidden;

          & img {
            width: 100%;
          }
        }

        .score {
          width: 100px;
          height: 36px;
          background: #ffce2e;
          box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-size: 28px;
          color: rgba(153, 95, 0, 1);
          text-align: center;
          line-height: 30px;
          margin-top: -22px;
        }

        .action {
          .action-item {
            position: relative;
            width: 50px;
            height: 40px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            & img {
              width: 27px;
              height: 26px;
            }
          }
        }

        .animation {
          position: absolute;
          bottom: 30px;
          right: 10px;
          color: #ffce2e;
          animation-name: like-animation;
          animation-duration: 0.5s;
          animation-iteration-count: 1;
          font-size: 18px;
        }

        .deduction {
          left: 10px !important;
          right: 0;
        }

        @keyframes like-animation {
          0% {
            bottom: 30px;
            opacity: 1;
          }
          100% {
            bottom: 90px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.close {
  position: absolute;
  left: 30px;
}
</style>
