<template>
  <div
    class="s-wrap"
    @click="selectStudent(student)"
  >
    <div class="s-star-wrap">
      <div
        class="s-star"
        :style="getStarStyle(index)"
        :key="index"
        v-for="(i,index) in student.goodNums"
      >
        <img
          src="@images/home/icon_star_yellow.png"
          alt="star"
        />
      </div>
    </div>
    <div class="s-avatar-wrap">
      <img
        :src="student.avatar"
        alt="avatar"
      />
    </div>
    <div class="s-nums-wrap">
      <div class="s-nums">
        <div class="good">{{ student.goodNums ? `+${student.goodNums}` : '0' }}</div>
        <div class="bad">{{ student.badNums ? `-${student.badNums}` : '0' }}</div>
      </div>
    </div>
    <div class="s-name size18">
      {{ student.studentName }}
    </div>
    <div class="school-num">{{student.schoolNum}}</div>
    <div
      class="s-selected"
      v-if="selected   && showChecked"
    >
      <img
        src="@images/home/icon_selected.png"
        class="selected-img"
        alt="selected"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Student',
  props: {
    student: {
      type: Object,
      default () {
        return {}
      },
    },
    showChecked: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      selected: false,
    }
  },
  watch:{
    student: {
      handler(newVal) {
        this.selected = newVal.checked
      },
      deep: true
    },
  },
  methods: {
    getStarStyle (index) {
      let style = ''
      let R = 30; //半径
      let count = this.student.goodNums
      style += 'width: ' + (100 / count) + '%;'
      let a = 180 / count;
      let du = (index + 1) * a - (a / 2);
      if (du > 90) {
        du = 180 - du;
      }
      let y = Math.sin(du * Math.PI / 180) * R;
      let mt = R - y;
      style += 'margin-top: ' + mt + 'px';
      return style;
    },
    selectStudent (item) {
      this.selected = !this.selected
      this.$emit('change', item)
    },
  },
}
</script>

<style scoped lang="less">
.s-wrap {
  position: relative;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .s-star-wrap {
    display: flex;
    height: 20px;
    width: 100px;
    position: relative;

    .s-star {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .s-avatar-wrap {
    border: 4px solid #ffffff;
    border-radius: 30px 30px 60px 60px;
    max-width: 100px;
    background: #dfe9ff;
    overflow: hidden;

    img {
      width: 100%;
      border: none;
    }
  }

  .s-nums-wrap {
    padding: 0 10px;
    margin-top: -10px;
    box-sizing: border-box;
    width: 100px;
  }

  .s-nums {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    > div {
      text-align: center;
      flex: 1;

      &.good {
        background: #fff0c0;
        color: #995f00;
        border: 2px solid #ffffff;
        border-radius: 10px 0px 0px 10px;
        border-right: none;
      }

      &.bad {
        background: #ffdcd3;
        color: rgba(214, 52, 52, 0.7);
        border: 2px solid #ffffff;
        border-radius: 0px 10px 10px 0px;
        border-left: none;
      }
    }
  }

  .s-name {
    text-align: center;
    color: #000000;
    margin-top: 5px;
  }

  .s-selected {
    position: absolute;
    left: 0;
    bottom: 24px;
    width: 120px;
    height: 79px;
    background: #ff8c29;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: 22px;
    border: 4px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 70%;
    }
  }
  .school-num{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }
}
</style>
