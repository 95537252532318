<template>
  <div>
    <div class="footer flex flex-center color5">
      广州融至益教育科技有限公司版权所有©{{ new Date().getFullYear() }}&#12288;
      <a class="color5"
         target="_blank"
         href="https://beian.miit.gov.cn/">ICP备案号 粤15112538</a>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.footer {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
}
</style>