<template>
  <div class="group">
    <div class="group-list">
      <div
        class="list flex"
        v-if="list.length > 0"
      >
        <Group
          v-for="(item,i) in list"
          :key="i"
          :item="item"
          :type="type"
          :menuIndex="index"
          @selected="selected"
          @edit="edit"
        ></Group>
      </div>
      <div
        v-if="isEmpty"
        class="empty flex"
      >
        <div class="empty-content flex-column">
          <div class="size16 color5">当前班级还没有小组</div>
          <img
            src="@images/home/group_bg.png"
            class="top20 img"
          >
          <div class="top20">
            <my-button
              size="small2"
              @click="$emit('addgroup')"
            >添加小组</my-button>
          </div>
        </div>
      </div>
    </div>

    <group-edit-modal
      :item="groupItem"
      :list="groupList"
      v-model="showEdit"
      @refresh="$emit('refresh')"
    ></group-edit-modal>
  </div>
</template>
<script>
import groupEditModal from '@/view/home/components/group/groupEditModal'
import MyButton from '@/components/mybutton/MyButton.vue'
import { getGroupList } from '@/api/home/group.js'
import { getAvatar } from '@/utils/random.js'
import Group from '@/components/group/Group.vue'
export default {
  components: {
    groupEditModal,
    MyButton,
    Group,
  },
  props: {
    type: {
      type: Number,
      default: 1, // 1 正常 2编辑组
    },
    index: {
      type: Number,
      default: 2, //菜单索引
    },
  },
  data () {
    return {
      showEdit: false,
      groupItem: null,
      groupList: null,
      list: [],
      userInfo: this.$store.getters.userInfo,
      isEmpty: false,
    }
  },
  mounted () {
    this.getGroupList()
  },
  methods: {
    getGroupList () {
      this.list = []
      getGroupList({ classId: this.userInfo.classId }).then((res) => {
        this.isEmpty = res.length === 0
        this.list = res
        this.list.map((item) => {
          item.checked = false
          item.studentEvaluationInfoVOList.map((item2) => {
            item2.avatar = getAvatar(item2)
            item2.checked = false
          })
          return item
        })
        this.$emit('change', this.list)
      })
    },
    change (item) {
      item.fold = !item.fold
      this.$forceUpdate()
    },
    edit (item) {
      this.groupItem = JSON.parse(JSON.stringify(item))
      this.groupList = JSON.parse(JSON.stringify(this.list))
      this.showEdit = true
    },
    selected (item) {
      if (this.index === 2) {
        this.list.map((item) => {
          item.checked = false
          return item
        })
        item.checked = !item.checked
        this.$emit('evaluate')
        this.$emit('change', this.list)
      } else {
        item.checked = !item.checked
        this.$emit('change', this.list)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.group {
  width: 100%;
  .group-list {
    width: 100%;
    padding: 200px 1vw 200px 1vw;

    .empty {
      justify-content: center;
      .empty-content {
        padding: 84px 140px 40px 140px;
        background: rgba(255, 255, 255, 0.96);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        align-items: center;
        .img {
          width: 260px;
        }
      }
    }
    .list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 400px);
      justify-content: center;
    }
  }
}
</style>
