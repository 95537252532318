import { render, staticRenderFns } from "./Evaluate.vue?vue&type=template&id=700bc81e&scoped=true&"
import script from "./Evaluate.vue?vue&type=script&lang=js&"
export * from "./Evaluate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700bc81e",
  null
  
)

export default component.exports