<template>
  <div>
    <div class="top44 count flex flex-space-between flex-column-center">
      <div
        :class="{'opacity7':number == 1,'click-style':reduceClickStyle}"
        @click="reduce()"
        class="reduce flex flex-center flex-column-center"
      >
        <div class="line"></div>
      </div>
      <div class="value size32">{{number}}</div>
      <div
        :class="{'opacity7':number == max,'click-style':addClickStyle}"
        @click="add()"
        class="add flex flex-center flex-column-center"
      >
        <div class="line"></div>
        <div class="line2"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    max: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      number: 1,
      addClickStyle: false,
      reduceClickStyle: false,
    }
  },
  methods: {
    reduce () {
      if (this.number > 1) {
        this.reduceClickStyle = true
        this.number -= 1
        this.$emit('change', this.number)
        console.log(this.number)
        setTimeout(() => {
          this.reduceClickStyle = false
        }, 200)
      }
    },
    add () {
      if (this.number < this.max) {
        this.addClickStyle = true
        this.number += 1
        this.$emit('change', this.number)
        setTimeout(() => {
          this.addClickStyle = false
        }, 200)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.count {
  min-width: 270px;
  background: rgba(255, 206, 46, 0.3);
  border-radius: 20px;
  padding: 20px 30px;
  .reduce,
  .add {
    width: 60px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
  }
  .line {
    width: 30px;
    height: 6px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50px;
  }
  .add {
    position: relative;
    .line2 {
      width: 6px;
      height: 30px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 50px;
      position: absolute;
    }
  }
  .reduce:hover,
  .add:hover {
    cursor: pointer;
  }
  .value {
    color: #000;
  }
}
.opacity7 {
  opacity: 0.7;
}
.opacity7:hover {
  cursor: not-allowed !important;
}
</style>