<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="460px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        <div>新增评价</div>
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <div class="form flex-column">
          <img
            @click="showSelectImagesModal = true"
            :src="form.habitIcon|| require('@images/home/default_habit.png')"
            class="img hover"
          >
          <div class="form-item flex top24">
            <div class="size16 color5 name">评价名称</div>
            <div class="flex1 input">
              <input
                v-model="form.habitName"
                placeholder="请输入评价名称"
              ></input>
            </div>
          </div>
          <div class="form-item flex top24">
            <div class="size16 color5 name">所属类型</div>
            <div class="flex1 input">
              <a-select
                style="width: 120px"
                v-model="defaultValue"
                @change="changeCate"
              >
                <a-select-option
                  :value="item.habitCatId"
                  v-for="(item,index) in cateList"
                  :key="index"
                >
                  {{item.habitCatName}}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="form-item flex top24">
            <div class="size16 color5 name">评价属性</div>
            <div class="flex1 input">
              <a-select
                style="width: 120px"
                v-model="typeValue"
                @change="changeType"
              >
                <a-select-option
                  :value="item.value"
                  v-for="(item,index) in habitTypeList"
                  :key="index"
                >
                  {{item.name}}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom flex flex-column-center flex-center">
        <div class="flex flex-column-center">
          <my-button
            size="small3"
            color="pink"
            @click="close()"
          >取消</my-button>
          <my-button
            class="left40"
            size="small3"
            @click="onSave()"
          >保存
          </my-button>
        </div>
      </div>
    </a-modal>
    <select-images-modal
      v-model="showSelectImagesModal"
      :list="list"
      @select="selectImage"
    ></select-images-modal>
  </div>
</template>
<script>
import MyButton from '../../../../components/mybutton/MyButton.vue'
import SelectImagesModal from './selectImagesModal.vue'
import { getSchEvaluationListIcon, getSchEvaluationListCat, schEvaluationCreate } from '@/api/home/evaluate.js'
export default {
  components: { MyButton, SelectImagesModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        habitIcon: '',
        habitName: '',
        habitCatId: '',
        habitType: 1
      },
      showSelectImagesModal: false,
      list: [],
      cateList: [],
      defaultValue: 0,
      habitTypeList: [{
        name: '表扬',
        value: 1
      }, {
        name: '待改进',
        value: 2
      }],
      typeValue: 1
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () {
      },
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.form.habitName = "";
        this.form.habitType = 1;
        this.form.habitIcon = "";
        this.getList()
        this.getCateList()
      }
    }
  },
  created () {
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
    },
    onSave () {
      if (this.form.habitName == 0) {
        return this.$message.error('请输入评价名称')
      }
      schEvaluationCreate(this.form).then(() => {
        this.$message.success('新增成功');
        this.close()
        this.$emit('refresh')
      })
    },
    getList () {
      getSchEvaluationListIcon().then(res => {
        this.list = res
      })
    },
    selectImage (e) {
      this.form.habitIcon = e.iconUrl
      console.log(this.form)
      console.log(e)
    },
    getCateList () {
      getSchEvaluationListCat().then(res => {
        this.cateList = res
        this.defaultValue = res[0].habitCatId
        this.form.habitCatId = res[0].habitCatId
      })
    },
    changeCate (e) {
      this.form.habitCatId = e
    },
    changeType (e) {
      this.form.habitType = e
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  padding-bottom: 120px;
  padding-top: 32px;
  .form {
    align-items: center;
    .img {
      width: 80px;
      height: 80px;
      background: #b9e6fa;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 60px;
      border: 2px solid #ffffff;
    }

    .form-item {
      width: 270px;
      padding: 14px 24px;
      background: rgba(255, 206, 46, 0.3);
      border-radius: 26px;

      .name {
        width: 100px;
      }

      input {
        background-color: transparent;
        width: 150px;
        text-align: right;
        font-size: 16px;
        color: #000;
      }

      input::-webkit-input-placeholder {
        font-size: 16px;
        color: #000;
      }
    }
  }
}
</style>
