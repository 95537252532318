export function getRandomNumber(length, number = 1) {
    if (number == 1) {
        return [Math.floor(Math.random() * length) + 1];
    } else {
        let result = [];
        for (let i = 0; i < number; i++) {
            result[i] = Math.floor(Math.random() * length) + 1;
            for (var j = 0; j < i; j++) {
                if (result[i] == result[j]) {
                    i--;
                }
            }
        }
        return result;
    }
}

const BOY_LEN = 30;
const BOY_SET = new Set();
const BOY_LIST = {};
const GIRL_LEN = 30;
const GIRL_SET = new Set();
const GIRL_LIST = {};

function getBoyNum(id, d) {
    let num = Math.trunc((parseInt(id + d) + new Date().getDate()) % BOY_LEN) + 1
    if (BOY_LIST['b'+ id]) {
        return num;
    }
    if (BOY_SET.size >= BOY_LEN) {
        BOY_LIST['b'+ id] = num;
        return num;
    }
    if (BOY_SET.has(num)) {
        num = getBoyNum(id, ++d);
    }
    BOY_SET.add(num);
    BOY_LIST['b'+ id] = num;
    return num;
}

function getGirlNum(id, d) {
    let num = Math.trunc((parseInt(id + d) + new Date().getDate()) % GIRL_LEN) + 1
    if (GIRL_LIST['g'+ id]) {
        return num;
    }
    if (GIRL_SET.size >= GIRL_LEN) {
        GIRL_LIST['g'+ id] = num;
        return num;
    }
    if (GIRL_SET.has(num)) {
        num = getGirlNum(id, ++d);
    }
    GIRL_SET.add(num);
    GIRL_LIST['g'+ id] = num;
    return num;
}

export function getAvatar(item) {
    if (item.gender === '1') {
        return require(`@images/avatar/girl${getGirlNum(item.studentId, 0)}.png`)
    } else {
        return require(`@images/avatar/boy${getBoyNum(item.studentId, 0)}.png`)
    }
}