<template>
  <div>
    <div class="selected-class">
      <back @click="$emit('back')"></back>
      <div class="top12 flex-column class-info">
        <class-number
          :name="item.className.substr(0,1)"
          :index="index"
        ></class-number>
        <div class="top24 size32 course-name flex flex-column-center">
          <div>{{item.courseSelectVOS[key].courseName}}课</div>
          <a-dropdown>
            <a
              class="ant-dropdown-link"
              @click="e => e.preventDefault()"
            >
              <img
                class="change hover"
                src="@images/account/icon_change.png"
              >
            </a>
            <a-menu
              slot="overlay"
              @click="handleMenuClick"
            >
              <a-menu-item
                v-for="(item,index) in item.courseSelectVOS"
                :key="index"
              >
                {{item.courseName}}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="top24 size16 color5">
          上课教师：{{teacherName}} <span class="color2">｜</span> 所在班级：{{item.gradeName||''}}{{item.className}} <span class="color2">｜</span> 学生数量：{{item.studentNums}}人
        </div>
        <div class="top24">
          <my-button @click="begin()">开始上课</my-button>
        </div>
        <div class="top20 color3">本堂授课时间45分钟</div>
      </div>
    </div>
  </div>
</template>
<script>
import back from '@/components/back/Back.vue'
import MyButton from '@/components/mybutton/MyButton.vue'
import ClassNumber from '../../../components/class/classNumber.vue'
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    teacherName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  components: {
    back,
    MyButton,
    ClassNumber,
  },
  data () {
    return {
      key: 0,
    }
  },
  methods: {
    begin () {
      this.$emit('begin')
    },
    handleMenuClick (e) {
      this.key = e.key
      this.$emit('change', e.key)
    },
  },
}
</script>
<style lang="less" scoped>
.selected-class {
  width: 720px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 32px 32px 48px 32px;

  .class-info {
    align-items: center;
  }

  .course-name {
    position: relative;
  }

  .change {
    position: absolute;
    right: -24px;
    width: 20px;
  }
}
</style>
