<template>
  <div>
    <img :src="logo" class="logo" alt="logo"/>
  </div>
</template>
<script>
import {getParams} from "@/utils/common";

export default {
  data() {
    return {
      logo: '',
      partnerList: {
        'default': {
          'logo-login': require('@images/partner/default/logo-login.png')
        },
        'XLKJ': {
          'logo-login': require('@images/partner/xinliukongjian/logo-login.png')
        }
      }
    }
  },
  mounted() {
    let pCode = getParams('pCode');
    if (!pCode) {
      pCode = localStorage.getItem('pCode');
    }
    if (this.partnerList[pCode]) {
      localStorage.setItem('pCode', pCode);
    } else {
      localStorage.removeItem('pCode');
      pCode = 'default';
    }
    this.logo = this.partnerList[pCode]['logo-login'];
  }
}
</script>
<style scoped lang="less">
.logo {
  position: absolute;
  left: 80px;
  top: 30px;
  width: 422px;
}
</style>
