<template>
  <div>
    <img
      src="@/assets/images/home/random.gif"
      width="600"
      v-if="type %2 == 0"
    />
    <img
      src="@/assets/images/home/random2.gif"
      width="600"
      v-else
    />
    <audio
      src="@/assets/audio/home/random.mp3"
      autoplay="autoplay"
      ref="audioRandom"
    ></audio>
  </div>
</template>
<script>

export default {
  data () {
    return {
      type:0
    }
  },
  mounted () {
    this.type = Math.round(Math.random()*10);
  }
}
</script>

<style lang="less" scoped>
</style>
