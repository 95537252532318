<template>
  <div>
    <div
      class="close flex flex-column-center"
      @click="close()"
    >
      <a-icon
        type="down"
        :style="{'color':'#000'}"
      />
      <div class="size16 color5 left12">关闭</div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    close () {
      this.$emit('click')
    },
  },
}
</script>
<style lang="less" scoped>
.close:hover {
  cursor: pointer;
}
</style>
