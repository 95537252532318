<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="460px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        <div>添加备注</div>
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <textarea
          class="remark-textarea"
          v-model="remark"
        ></textarea>
      </div>
      <div class="bottom flex flex-column-center flex-space-between">
        <div class="flex flex-column-center">
          <my-button
            size="small3"
            color="pink"
            @click="close()"
          >返回</my-button>
          <my-button
            class="left40"
            size="small3"
            @click="onEvaluate()"
          >备注并评价
          </my-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import MyButton from '../../../../components/mybutton/MyButton.vue'
export default {
  components: { MyButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      remark: ''
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () {
      },
    }
  },
  created () {
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
    },
    onEvaluate () {
      this.close()
      this.$emit('evaluate', this.remark)
      this.remark = '';
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  padding-bottom: 120px;
  padding-top: 32px;
}
.remark-textarea {
  width: 320px;
  height: 70px;
  background: #fafafb;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  resize: none;
}
</style>
