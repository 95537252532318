import Vue from "vue";
import Vuex from "vuex";
import user from "./module/user";
import navbar from "./module/navbar.js";
import getters from "./getters";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: "taoxiaoyi",
    storage: window.localStorage,
    reducer: (state) => ({
        user: state.user,
    }),
});

export default new Vuex.Store({
    modules: {
        user,
        navbar,
    },
    getters,
    plugins: [vuexLocal.plugin],
});