<template>
  <div>
    <div class="cd-wrap">
      <div class="cd-bg">
        <MyCountBtn
          v-model="min10"
          :read-only="!canEdit"
        ></MyCountBtn>
        <MyCountBtn
          v-model="min"
          :read-only="!canEdit"
        ></MyCountBtn>
      </div>
      <div class="cd-do">:</div>
      <div class="cd-bg">
        <MyCountBtn
          v-model="second10"
          :max="5"
          :read-only="!canEdit"
        ></MyCountBtn>
        <MyCountBtn
          v-model="second"
          :read-only="!canEdit"
        ></MyCountBtn>
      </div>
    </div>
    <div class="bottom flex flex-column-center flex-center">
      <close
        @click="close()"
        class="close"
      ></close>
      <div v-if="!isCount">
        <my-button
          size="big"
          @click="start()"
        >开始计时
        </my-button>
      </div>
      <div
        v-else
        class="flex"
      >
        <my-button
          size="small3"
          class="left40"
          color="pink"
          @click="restart()"
        >重新开始
        </my-button>
        <my-button
          v-if="timer"
          size="small3"
          class="left40"
          @click="pause()"
        >暂停计时
        </my-button>
        <my-button
          v-else
          size="small3"
          class="left40"
          @click="count()"
        >继续计时
        </my-button>
      </div>
    </div>
    <audio
      loop="loop"
      src="@/assets/audio/count/di.mp3"
      ref="audio"
    ></audio>
    <audio
      loop="loop"
      src="@/assets/audio/count/didi.mp3"
      ref="audio2"
    ></audio>
  </div>
</template>
<script>
import close from '@/components/close/Index.vue'
import MyCountBtn from '../mybutton/MyCountBtn.vue'
import MyButton from '../mybutton/MyButton.vue'

export default {
  components: { close, MyCountBtn, MyButton },
  data () {
    return {
      isPlay: false,
      isPlay2: false,
      isCount: false,
      canEdit: true,
      oldContTime: 0,
      countTime: 0,
      min10: 0,
      min: 0,
      second10: 0,
      second: 0,
      timer: null
    }
  },
  methods: {
    getCountTime () {
      return this.min10 * 600 + this.min * 60 + this.second10 * 10 + this.second;
    },
    start () {
      this.count();
    },
    pause () {
      clearInterval(this.timer);
      this.timer = null;
      // this.canEdit = true;
    },
    restart () {
      clearInterval(this.timer);
      this.timer = null;
      this.countTime = null;
      this.oldContTime = null;
      this.renderNum(0);
      this.canEdit = true;
      this.isCount = false;
    },
    stop () {
      clearInterval(this.timer);
      this.timer = null;
      this.canEdit = true;
      this.isCount = false;
      this.$refs.audio.pause();
      this.$refs.audio2.pause();
    },
    count (flag) {
      if (!flag) {
        this.countTime = this.getCountTime();
        this.oldContTime = this.countTime;
      }
      if (this.countTime <= 0) {
        return;
      }
      this.isCount = true;
      this.canEdit = false;
      const that = this;
      this.timer = setInterval(() => {
        this.countTime -= 1
        if (this.countTime < 0) {
          that.stop();
          return;
        }
        if (this.countTime <= 10) {
          this.$refs.audio.play();
          if (this.countTime <= 2) {
            this.$refs.audio.pause();
            this.$refs.audio2.play();
          }
        }
        that.renderNum(that.countTime);
      }, 1000)
    },
    renderNum (countTime) {
      this.min10 = Math.trunc(countTime / 600);
      this.min = Math.trunc(countTime / 60 % 10);
      this.second10 = Math.trunc(countTime % 60 / 10);
      this.second = countTime % 60 % 10;
    },
    close () {
      this.$emit("close");
    }
  },
}
</script>
<style lang="less" scoped>
.cd-wrap {
  display: flex;
  align-items: center;
}

.cd-do {
  font-size: 120px;
  margin: -40px 10px 0 10px;
}

.cd-bg {
  width: 315px;
  background: rgba(115, 189, 255, 0.3);
  border-radius: 20px;
  display: flex;
  padding: 10px 20px;

  > div {
    flex: 1;
  }
}

.close {
  position: absolute;
  left: 30px;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 70px;
  background: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  max-height: 80px;
}

.content {
  width: 720px;
  height: 260px;
  background: rgba(255, 206, 46, 0.3);
  border-radius: 20px;
}

.padding20 {
  padding: 0 20px;
}

.pad-top {
  padding-top: -15px;
}
</style>