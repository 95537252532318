<template>
  <div class="navbar flex">
    <div class="flex flex-column-center">
      <img :src="logo" class="name" alt="logo" />
      <div class="size14 left12">{{ title }}</div>
    </div>
    <div class="size16">
      {{ userInfo.teacherName }}正在上{{ userInfo.courseName }}
    </div>
  </div>
</template>

<script>
import {getParams} from "@/utils/common";

export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo,
      logo: '',
      title: '',
      partnerList: {
        'default': {
          'logo-home': require('@images/partner/default/logo-home.jpg'),
          'title': '淘小益课堂助手'
        },
        'XLKJ': {
          'logo-home': require('@images/partner/xinliukongjian/logo-home.png'),
          'title': ''
        }
      }
    }
  },
  mounted() {
    let pCode = getParams('pCode');
    if (pCode) {
      if (this.partnerList[pCode]) {
        localStorage.setItem('pCode', pCode);
      } else {
        localStorage.removeItem('pCode');
        pCode = 'default';
      }
    } else {
      pCode = localStorage.getItem('pCode');
      pCode = pCode ? pCode : 'default';
    }
    this.logo = this.partnerList[pCode]['logo-home'];
    this.title = this.partnerList[pCode]['title'];
  }
}
</script>

<style lang="less" scoped>
.navbar {
  width: 100%;
  height: 46px;
  background: #ffce2e;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  color: #000;

  .name {
    //width: 24px;
    height: 24px;
    border-radius: 2px;
  }
}
</style>