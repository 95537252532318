export default {
    token: (state) => state.user.token,
    userInfo: (state) => state.user.userInfo,
    isHide: (state) => state.navbar.isHide,
    currentClassName: (state) => state.user.currentClassName,
    classId: (state) => state.user.userInfo.classId,
    courseId: (state) => state.user.userInfo.courseId,
    teacherId: (state) => state.user.userInfo.teacherId,
    classEndTimestamp: (state) => state.user.userInfo.classEndTimestamp,
    rank: (state) => state.user.rank,
};