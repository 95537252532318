<template>
  <div>
    <div class="menu-side flex">
      <!--      <div class="tips size16">本班共{{userInfo.totalStudentNums}}人，<span class="color-bule">{{userInfo.joinedNums}}人</span>加入班级，<span class="color-bule">{{userInfo.unJoinNums}}人</span>未加入</div>-->
      <div
        class="menu-content flex animate__animated animate__fadeInUp"
        v-if="type == 1"
      >
        <div class="group flex size16 color5">
          <div
            @click="change(index)"
            v-for="(item,index) in menuList"
            :key="index"
            class="group-item"
            :class="{'active' : index == currentIndex}"
          >{{item}}</div>
        </div>
        <div class="flex">
          <div
            v-if="currentIndex == 0 || currentIndex == 1"
            class="menu-item"
            @click="random()"
          >
            <my-button size="min">随机点人</my-button>
          </div>
          <div
            v-if="currentIndex == 2 || currentIndex == 3"
            class="menu-item"
            @click="groupRandom()"
          >
            <my-button size="min">随机点组</my-button>
          </div>
          <div
            v-if="currentIndex == 1"
            class="menu-item"
            @click="checkAll()"
          >
            <my-button size="min">全选</my-button>
          </div>
          <div
            v-if="currentIndex == 2 || currentIndex == 3"
            class="menu-item"
            @click="groupRandoPeople()"
          >
            <my-button size="min">每组抽选</my-button>
          </div>
          <div
            class="menu-item"
            @click="evaluateStudent()"
          >
            <my-button
              size="min"
              :isdisabled="currentIndex == 0 || currentIndex == 2"
            >发评价</my-button>
          </div>
          <div class="menu-item">
            <menu-popover
              :index="currentIndex"
              @openAdwardList="openAdwardList"
              @openRankList="openRankList"
              @openPointRace="$emit('openPointRace')"
              @openKnockout="$emit('openKnockout')"
              @editGroup="editGroup"
              @countDown="countDown"
            ></menu-popover>
          </div>
        </div>

      </div>
      <div
        class="menu-content flex flex-center animate__animated animate__slideInUp"
        v-if="type == 2"
      >
        <my-button
          size="small3"
          color="pink"
          @click="exitEdit()"
        >退出编辑模式</my-button>
        <my-button
          size="small3"
          class="left30"
          @click="$emit('addgroup')"
        >添加小组</my-button>
      </div>
    </div>
  </div>
</template>
<script>
import MyButton from '@/components/mybutton/MyButton.vue'
import MenuPopover from './menuPopover.vue'
export default {
  components: { MyButton, MenuPopover },
  props: {
    type: {
      type: Number,
      default: 1, // 1 正常 2编辑组导航
    },
  },
  data () {
    return {
      currentIndex: 0,
      menuList: ['选单人', '选多人', '选一组', '选多组'],
      userInfo: this.$store.getters.userInfo,
    }
  },
  methods: {
    change (index) {
      this.currentIndex = index
      this.$emit('change', this.currentIndex)
    },
    random () {
      this.$emit('random')
    },
    checkAll () {
      this.$root.$emit("checkAll");
    },
    evaluateStudent () {
      if (this.currentIndex != 0 || this.currentIndex != 2) {
        this.$emit('evaluateStudent')
      }
    },
    countDown () {
      this.$emit('countDown')
    },
    openRankList () {
      this.$emit('openRankList')
    },
    openAdwardList () {
      this.$emit('openAdwardList')
    },
    editGroup () {
      this.$emit('editGroup')
    },
    exitEdit () {
      this.$emit('exitEdit')
    },
    groupRandom () {
      this.$emit('groupRandom')
    },
    groupRandoPeople () {
      this.$emit('groupRandoPeople')
    },
    finishClass () {
      this.$store.dispatch('finishClass').then(() => {
        this.$router.push('/rank')
      })
    }
  },
}
</script>
<style lang="less" scoped>
.menu-side {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
  z-index: 9;
  justify-content: center;
  .tips {
    position: fixed;
    bottom: 130px;
  }
  .tips:hover {
    cursor: pointer;
  }
  .menu-content {
    position: relative;
    background: rgba(255, 255, 255, 0.96);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 20px 5vw;
    align-items: center;
    max-width: 940px;
    display: flex;
    justify-content: center;
    .group {
      background: #f1f7ff;
      box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      overflow: hidden;
      .group-item {
        padding: 12px 1vw;
      }

      .group-item:hover {
        cursor: pointer;
      }

      .group-item.active {
        background-color: rgba(255, 206, 46, 1);
        color: #000;
        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      }
    }

    .menu-item {
      margin-left: 20px;
    }
  }
}
</style>
