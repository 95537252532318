<template>
  <div>
    <div
      :class="[bgClass]"
      class="class-number flex flex-center flex-column-center"
    >{{name}}</div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    bgClass () {
      return this.index % 3 == 0
        ? 'bg0'
        : this.index % 3 == 1
          ? 'bg1'
          : this.index % 3 == 2
            ? 'bg2'
            : ''
    },
  },
}
</script>
<style lang="less" scoped>
.class-number {
  width: 120px;
  height: 120px;
  border-radius: 90px;
  font-size: 84px;
  color: #ffffff;
}

.bg0 {
  background-color: #c8c2f1;
}
.bg1 {
  background-color: #e9c84d;
}
.bg2 {
  background-color: #fbb5a8;
}
</style>
