import * as types from "../mutation-types";

const state = {
    isHide: false,
};

const getters = {
    navbar: (state) => state,
};

const actions = {};

const mutations = {
    [types.UPDATE_NAV](state, res) {
        state.isHide = res.isHide;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};