<template>
  <div>
    <div class="class-name flex-column size24">
      <div class="name  flex flex-column-center flex-center">
        <div> {{currentClassName}}</div>
      </div>
      <div class="statistic flex">
        <div class="flex1 color1 div-center">+{{goodNums}}</div>
        <div class="flex1 color2 div-center">-{{badNums}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    badNums: {
      type: Number,
      default: 0
    },
    goodNums: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      userInfo: this.$store.getters.userInfo,
      currentClassName: this.$store.getters.currentClassName,
    }
  },
}
</script>
<style lang="less" scoped>
.class-name {
  position: absolute;
  left: 0;
  top: 16px;
  width: 100%;
  align-items: center;
  color: #fff;
  z-index: 9;
  .name {
    width: 510px;
    height: 84px;
    background-image: url("../../assets/images/home/icon_class_bg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    & div {
      position: relative;
      top: -8px;
    }
  }

  .statistic {
    position: relative;
    top: -30px;
    width: 104px;
    height: 28px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2),
      0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    border: 2px solid #ffffff;
    font-size: 16px;
  }

  .color1 {
    color: #995f00;
    background-color: rgba(255, 206, 46, 0.4);
    border-radius: 10px 0px 0px 10px;
  }

  .color2 {
    color: rgba(214, 52, 52, 0.7);
    background-color: rgba(255, 168, 145, 0.4);
    border-radius: 0px 10px 10px 0px;
  }
}
</style>