<template>
  <div>
    <div
      @click="change(index)"
      class="student-item flex-column"
    >
      <div
        class="avatar"
        :class="{'active':isselected}"
      >
        <group-image
          v-if="showGroupImage"
          :item="item"
        ></group-image>
        <img
          v-else
          :src="item.avatar || require('@images/home/avatar.png')"
          class="avatar-img"
        >
        <div
          v-if="isselected"
          class="selected flex flex-center flex-column-center"
        >
          <img
            src="@images/home/icon_selected.png"
            class="icon_selected"
          >
        </div>
      </div>
      <div class="student-name size16 color7 div-center">{{ name }}</div>
      <div
        class="div div-center size16"
        v-if="item.name"
      >{{item.name}}</div>
    </div>
  </div>
</template>
<script>
import { subText } from '@/utils/common.js'
import groupImage from '@/components/group/GroupImage.vue'
export default {
  components: {
    groupImage
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    showGroupImage: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    name () {
      if (this.item.studentName) {
        return subText(this.item.studentName, 5)
      } else {
        return subText(this.item.groupName, 5)
      }
    },
    isselected: {
      get () {
        return this.selected
      },
      set () {

      }
    }
  },
  methods: {
    change (index) {
      this.isselected = !this.isselected
      this.$emit('change', index)
    }
  }
}
</script>
<style lang="less" scoped>
.student-item {
  margin-left: 40px;
  margin-bottom: 40px;

  .avatar {
    position: relative;

    .avatar-img {
      width: 116px;
      height: 116px;
      border-radius: 50%;
      border: 4px solid #ffffff;
      border-radius: 50%;
      background: #dfe9ff;
    }

    .selected {
      position: absolute;
      left: 0;
      top: 0;
      width: 116px;
      height: 116px;
      z-index: 8;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      border: 2px solid #fff;

      .icon_selected {
        width: 71px;
      }
    }
  }

  .avatar.active::before {
    content: "";
    position: absolute;
    border: 8px solid #ff8c29;
    width: 132px;
    height: 132px;
    border-radius: 50%;
    left: -8px;
    top: -8px;
  }

  .student-name {
    width: 100px;
    height: 30px;
    background: #ffeecd;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: 60px;
    border: 2px solid #ffffff;
    z-index: 9;
    margin: -15px auto 0px;
  }
}

.student-item:hover {
  cursor: pointer;
}
</style>
