<template>
  <div>
    <div class="flex rank-list">
      <div class="rank-item">
        <div class="name-wrap">
          <img
            v-if="currentIndex == 0"
            src="@images/account/best_people.png"
            class="name"
          >
          <img
            v-else
            src="@images/account/best_group.png"
            class="name"
          >
        </div>
        <div
          v-if="currentIndex == 0"
          class="content flex flex-center flex-column-center  animate__animated animate__fadeIn"
        >
          <div class="bg size16 color5">
            <img
              src="@images/account/rank_number_bg.png"
              class="bg"
              v-if="rank.personage.length > 0"
            >

            <div v-if="rank.personage.length > 0">
              <div
                v-for="(item,index) in rank.personage"
                :key="index"
              >
                <img
                  :src="getAvatar(item.studentEvaluationInfoVO)"
                  class="avatar"
                  :class="{'first':index == 0,'second':index == 1,'third':index == 2}"
                >
                <div
                  class="student-name"
                  :class="{'first-name ':index == 0,'second-name':index == 1,'third-name':index == 2}"
                >{{item.studentEvaluationInfoVO.studentName}}</div>
              </div>
            </div>
            <div
              v-else
              class="empyt flex-column flex-center flex-column-center "
            >
              <img
                src="@images/home/group_bg.png"
                width="260"
              >
              <span class="top24 size24 color5"> 暂无个人上榜</span>
            </div>
          </div>

        </div>
        <div
          v-else
          class="content flex flex-center flex-column-center animate__animated animate__fadeInRight"
        >
          <div class="bg size16 color5">
            <div
              v-if="rank.group.length  > 0"
              class="group-list"
            >
              <div
                v-for="(item,index) in rank.group"
                :key="index"
                class="group-item  flex flex-column-center"
                :class="{'left40':index != 1}"
              >
                <img
                  :src="require(`@images/account/rank_${index + 1}.png`)"
                  class="rank_number"
                >
                <img
                  src="@images/home/logo.jpg"
                  class="grounp-img"
                >
                <div class="left16 flex-column group-info">
                  <div class="size18 color8">{{item.groupName}}</div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="empyt flex-column flex-center flex-column-center "
            >
              <img
                src="@images/home/group_bg.png"
                width="260"
              >
              <span class="top24 size24 color5"> 暂无小组上榜</span>
            </div>
          </div>
        </div>
        <div
          class="hover left-arrows arrows"
          @click="change()"
        ></div>
        <div
          class="hover right-arrows arrows"
          @click="change()"
        ></div>
        <div
          class="hover div-center back"
          @click="goLogin()"
        >返回登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAvatar } from '@/utils/random.js'
export default {
  data () {
    return {
      currentIndex: 0, //0个人 1小组
      rank: this.$store.getters.rank,
      getAvatar: getAvatar
    }
  },
  created () {
    if (this.rank.personage != undefined) {
      this.rank.personage.map((item) => {
        item.gender = item.studentEvaluationInfoVO.gender
        console.log(item)
        return item
      })
    }
  },
  methods: {
    change () {
      this.currentIndex = this.currentIndex == 1 ? 0 : 1
    },
    goLogin () {
      this.$router.push('/login')
    },
  },
}
</script>
<style lang="less" scoped>
.rank-list {
  padding: 0 100px;
  height: 500px;
  .rank-item {
    width: 862px;
    position: relative;
    background-image: url("../../../assets/images/account/bg.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    .arrows {
      position: absolute;
      top: 180px;
      width: 100px;
      height: 100px;
      background: transparent;
    }
    .left-arrows {
    }
    .right-arrows {
      right: 0;
    }

    .name-wrap {
      position: absolute;
      left: 200px;
      top: 22px;
      .name {
        width: 64px;
      }
    }
    .content {
      height: 100%;
      .bg {
        position: relative;
        width: 400px;
        z-index: 1;
        .avatar {
          position: absolute;
          border-radius: 50%;
          background: #dfe9ff;
        }
        .first {
          width: 112px;
          top: 25px;
          left: 143px;
        }
        .second {
          width: 90px;
          top: 80px;
          left: 20px;
        }
        .third {
          width: 90px;
          top: 80px;
          left: 295px;
        }
        .student-name {
          position: absolute;
          width: 100px;
          text-align: center;
          z-index: 2;
        }
        .first-name {
          top: 170px;
          left: 150px;
        }
        .second-name {
          left: 10px;
          top: 200px;
        }
        .third-name {
          left: 295px;
          top: 200px;
        }
      }
      .group-list {
        .group-item {
          max-width: 360px;
          padding: 20px;
          background: linear-gradient(180deg, #e8e4ff 0%, #dbf7ec 100%);
          border-radius: 20px;
          border: 2px solid #ffffff;
          margin-bottom: 16px;
          .rank_number {
            width: 24px;
          }
          .grounp-img {
            width: 60px;
            height: 60px;
            background: #b9e6fa;
            box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 60px;
            border: 4px solid #ffffff;
          }
        }
      }
    }
  }
}
</style>
