<template>
  <div
    class="flex back"
    @click="$emit('click')"
  >
    <a-icon
      type="left"
      :style="{ fontSize: '20px',color:'rgba(0,0,0,0.9000)' }"
    />
    <div class="size16 color5 left12">返回</div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
.back {
  align-items: center;
}
.back:hover {
  cursor: pointer;
}
</style>