import axios from "axios";
import Config from "@/settings";
import Vue from "vue";
import store from "@/store";

// 创建axios实例
const service = axios.create({
    baseURL: Config.baseUrl, // api 的 base_url
    timeout: Config.timeout, // 请求超时时间
});

let GLOBAL_POST_FLAG = false;
let T = null;
let toast = null;
let reqNum = 0;
const CancelToken = axios.CancelToken;

// request拦截器
service.interceptors.request.use(
    (config) => {
        //TODO:防止重复提交
        if (config.submitOnce) {
            if (GLOBAL_POST_FLAG) {
                config.cancelToken = new CancelToken((c) => {
                    c(`拦截重复的请求`);
                });
                return config;
            }
            GLOBAL_POST_FLAG = true;
        }
        if (config.hiddenLoading == undefined && reqNum == 0) {
            toast = Vue.prototype.$Tmessage.loading("加载中..", 0);
        }

        reqNum++;

        if (store.getters.token) {
            config.headers["Authorization"] = "Bearer " + store.getters.token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }

        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (res) => {
        //TODO:防止重复提交
        if (T === null && GLOBAL_POST_FLAG) {
            T = setTimeout(function() {
                GLOBAL_POST_FLAG = false;
                T = null;
            }, 700);
        }

        setTimeout(() => {
            if (reqNum > 0) {
                reqNum--;
            }
        }, 300);

        if (toast) {
            toast();
            toast = null;
        }
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = res.data.msg || "服务器异常";
        if (code == 200) {
            return res.data.data;
        } else if (code === 401) {
            Vue.prototype.$Tmodal.error({
                title: "登录已过期",
                content: "很抱歉，登录已过期，请重新登录",
                okText: "重新登录",
                mask: false,
                onOk: () => {
                    store.dispatch("logout").then(() => {
                        window.location.reload();
                    });
                },
            });
            return Promise.reject(new Error(msg));
        } else if (code === 402) {
            store.dispatch("logout").then(() => {
                location.href = "/login";
            });
            Vue.prototype.$Tmodal.error({
                title: "提示",
                content: "很抱歉，课堂已下课，请重新登录",
                okText: "重新登录",
                mask: false,
                onOk: () => {},
            });
            return Promise.reject(new Error(msg));
        } else if (code === 500) {
            Vue.prototype.$Tnotification.error({
                message: "系统提示",
                description: msg,
                duration: 4,
            });
            return Promise.reject(new Error(msg));
        } else {
            return Promise.reject(res.data);
        }
    },
    (error) => {
        GLOBAL_POST_FLAG = false;
        let message = "系统升级中，请稍后再试";
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    message = error.response.data.message;
                    break;
                default:
                    message = "服务器异常";
                    break;
            }
            Vue.prototype.$Tnotification.error({
                message: "系统提示",
                description: message,
                duration: 4,
            });
        }
        return Promise.reject(error);
    }
);
export default service;
