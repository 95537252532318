import LoginComponent from '@/view/account/login/login.vue'
import selectComponent from '@/view/account/class/select.vue'
import rankComponent from '@/view/account/class/rank.vue'
import LayoutsComponent from '@/components/layouts/BasicLayout.vue'
import HomeComponent from '@/view/home/index/index.vue'

const loginRouter = {
    path: '/login',
    name: 'login',
    component: LoginComponent
}


const selectClassRouter = {
    path: '/select',
    name: 'select',
    component: selectComponent
}


const rankRouter = {
    path: '/rank',
    name: 'rank',
    component: rankComponent
}
const homeRouter = {
    path: '/',
    name: 'root',
    component: LayoutsComponent,
    redirect: '/home',
    children: [{
        path: '/home',
        name: 'home',
        component: HomeComponent
    }, ]
};


const routes = [
    loginRouter,
    homeRouter,
    selectClassRouter,
    rankRouter
]

export default routes