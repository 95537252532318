import request from "@/utils/request";
import config from "@/settings.js";

export function getStudentEvaluationList(params) {
    return request({
        url: config.requestUrlModel +
            "/classroom/classRoomEvaluation/getStudentEvaluationList",
        method: "get",
        params,
    });
}

export function getRewardRank(params) {
    return request({
        url: config.requestUrlModel + "/classroom/classRoomEvaluation/oneHallOfFame",
        method: "get",
        params,
    });
}

export function getGroupRewardRank(params) {
    return request({
        url: config.requestUrlModel + "/classroom/classRoomEvaluation/groupHallOfFame",
        method: "get",
        params,
    });
}

export function extendedClassTime(params) {
    return request({
        url: config.requestUrlModel + "/classroom/general/extendedClassTime",
        method: "get",
        params,
    });
}

export function allCardNums(params) {
    return request({
        url: config.requestUrlModel + "/classroom/classRoomEvaluation/allCardNums",
        method: "get",
        params,
    });
}


export function getPowerRank(params) {
    return request({
        url: config.requestUrlModel + "/classroom/classRoomEvaluation/getClassPowerList",
        method: "get",
        params,
    });
}