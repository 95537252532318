<template>
  <div v-if="isReady">
    <div class="select-page flex-column">
      <my-header></my-header>
      <div
        v-if="!isSelected"
        class="content flex-column"
      >
        <div
          class="size24 color5 div-center"
          v-if="classList.length > 0"
        >{{userInfo.teacherName}}您好，请选择即将上课的班级，或<span class="underline hover blue" @click="logout()">“退出账号”</span></div>
        <div
          class="size24 color5 div-center"
          v-else
        >
          <div>
            {{userInfo.teacherName}}老师您好，您没有任课任何班级，无法进行上课</div>
          <div
            class="size16 color8 underline top120 hover"
            @click="logout()"
          >退出</div>
        </div>
        <div
          class="top64 class-list"
          v-if="classList.length > 0"
        >
          <class-item
            v-for="(item,i) in classList"
            :key="i"
            :index="i"
            :item="item"
            :selected="item.status == 1"
            @click="selectClass"
          ></class-item>
        </div>
      </div>
      <div
        class="selected-class animate__animated animate__flipInX
"
        v-else
      >
        <selected-class
          :teacherName="userInfo.teacherName"
          :item="classList[currentIndex]"
          :index="currentIndex"
          @back="isSelected = false"
          @begin="courseBegin()"
          @change="changeCourse"
        ></selected-class>
      </div>
    </div>
  </div>

</template>
<script>
import ClassItem from '../components/classItem.vue'
import myHeader from '../components/myHeader.vue'
import SelectedClass from '../components/selectedClass.vue'
import { getClass, classesBegin ,finishClass} from '@/api/account/user.js'
import { mapState } from "vuex";
export default {
  components: { myHeader, ClassItem, SelectedClass },
  computed: {
    ...mapState({
      teacherId: state => state.user.userInfo.teacherId,
      courseId: state => state.user.userInfo.courseId
    })
  },
  data () {
    return {
      isReady: false,
      isSelected: false,
      userInfo: this.$store.getters.userInfo,
      classList: [],
      form: {
        classId: '',
        courseId: '',
      },
      currentIndex: '',
    }
  },
  created () {
    this.getClassList()
  },
  methods: {
    changeCourse (key) {
      this.form.courseId =
        this.classList[this.currentIndex].courseSelectVOS[key].courseId
    },
    getClassList () {
      getClass().then((res) => {
        this.classList = res ? res.classSelectVOList : []
        this.initClassSelect()
        console.log(this.classList)
        this.isReady = true
      })
    },
    selectClass (index) {
      const { classId, courseSelectVOS, status } = this.classList[index]
      // if (status == 1) {
      //   return this.$message.error('该班级有教师在上课，请选择其他班级上课！')
      // }
      this.classList[index].checked = true
      this.currentIndex = index
      this.form.classId = classId
      this.form.courseId = courseSelectVOS[0].courseId
      this.isSelected = true
    },
    initClassSelect () {
      this.classList.map((item) => {
        item.checked = false
        return item
      })
    },
    courseBegin () {
      const { gradeName, className ,status, classId } = this.classList[this.currentIndex]
      if (status == 1) {
        this.$Tmodal.confirm({
          title: "提示",
          content: "有老师在上课中，您确定要替换当前老师，为该班级上课吗？",
          okText: "确定",
          cancelText:"再想想",
          mask: false,
          onOk: () => {
            finishClass({
              classId: classId,
            }).then(() => {
              this.beginHandle(gradeName,className)
            })
          },
        });
      } else {
        this.beginHandle(gradeName,className)
      }
    },
    beginHandle(gradeName,className){
      classesBegin(this.form).then(() => {
        this.$store
                .dispatch('getUserInfo')
                .then(() => {
                    const name = (gradeName || '')  + className;
                    this.$store.commit('SET_CLASSNAME', name)
                    const sk = this.teacherId + '-' + this.courseId + '-randomList';
                    sessionStorage.removeItem(sk);
                    this.$router.replace('/')
                })
      })
    },
    logout () {
      this.$store.dispatch('logout').then(() => {
        window.location.reload()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.select-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(360deg, #edf4ff 0%, #faf6f5 100%);
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}
.content {
  width: 100%;
}
.class-list {
  padding: 0 3vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-content: center;
}
</style>
