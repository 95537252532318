<template>
  <div
    class="options-menu  hover flex-column"
    @click="showMenu()"
  >
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="menu-popover ">
      <a-popover
        v-model="show"
        trigger="click"
        placement="top"
      >
        <template slot="content">
          <my-button
            size="min" @click="scale">
            {{zoom == 1 ? '缩小界面' : '放大界面'}}
          </my-button>
          <my-button
            class="top20"
            size="min"
            @click="sortHandle()"
          >{{ sort == 1 ? '姓名排序' : '学号排序' }}
          </my-button>
          <my-button
            size="min"
            class="top20"
            @click="countDown()"
          >计时器
          </my-button>
          <my-button
            size="min"
            class="top20"
            @click="openPointRace()"
            v-if="index == 1 || index == 3"
          >积分赛
          </my-button>
          <my-button
            size="min"
            class="top20"
            @click="openKnockout()"
            v-if="index == 1 || index == 3"
          >淘汰赛
          </my-button>
          <my-button
            size="min"
            class="top20"
            @click="openRankList()"
          >实力榜
          </my-button>
          <my-button
            size="min"
            class="top20"
            @click="openAdwardList()"
          >看榜单
          </my-button>
          <my-button
            @click="editGroup()"
            v-if="index == 2 || index == 3"
            size="min"
            class="top20"
          >编辑组
          </my-button>
          <a-popconfirm
            title="是否结束当前课堂"
            ok-text="确定"
            cancel-text="取消"
            @confirm="finishClass"
          >
            <my-button
              size="min"
              class="top20"
              color="pink"
            >下课
            </my-button>
          </a-popconfirm>

        </template>
      </a-popover>
    </div>
  </div>

</template>
<script>
import MyButton from '@/components/mybutton/MyButton.vue'
import { mapState } from "vuex";

export default {
  components: {
    MyButton,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      teacherId: state => state.user.userInfo.teacherId,
      courseId: state => state.user.userInfo.courseId
    }),
  },
  data () {
    return {
      show: false,
      sort: 1,
      zoom: 1
    }
  },
  methods: {
    finishClass () {
      const sk = this.teacherId + '-' + this.courseId + '-randomList';
      sessionStorage.removeItem(sk);
      this.$store.dispatch('finishClass').then(() => {
        this.$router.push('/rank')
      })
    },
    openRankList () {
      this.show = false
      this.$emit('openRankList')
    },
    openAdwardList () {
      this.show = false
      this.$emit('openAdwardList')
    },
    openPointRace () {
      this.show = false
      this.$emit('openPointRace')
    },
    openKnockout () {
      this.show = false
      this.$emit('openKnockout')
    },
    showMenu () {
      this.show = true
    },
    editGroup () {
      this.show = false
      this.$emit('editGroup')
    },
    countDown () {
      this.show = false
      this.$emit('countDown')
    },
    sortHandle(){
      this.sort = this.sort == 1 ? 2 : 1
      this.$root.$emit("sortList",this.sort);
    },
    scale(){
      this.show = false;
      this.zoom =  this.zoom == 1 ? 0.65 : 1;
      document.body.style.zoom = this.zoom;
      document.body.style.background = "linear-gradient(360deg, #edf4ff 0%, #fff2d2 100%)"
    }
  },
}
</script>
<style lang="less" scoped>
.menu-popover {
  position: relative;
  top: -50px;
}

.options-menu {
  // position: absolute;
  // top: 27px;
  // right: 24px;
  width: 16px;
  height: 36px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  align-items: center;
  justify-content: space-around;
  padding: 4px 0;
}

.dot {
  width: 4px;
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}
</style>
