<template>
  <div>
    <div class="login-content flex flex-center">
      <div class="login-form flex-column flex-column-center flex-center color5">
        <div>
          <p class="size24">扫码登录</p>
          <p class="size16">淘小益课堂助手</p>
        </div>
        <div class="code">
          <img :src="url">
          <div class="mark" v-if="showMask">
            <div class="color-brand">二维码超过有效时间</div>
            <div class="btn-refresh" @click="$emit('refresh')">重新获取</div>
          </div>
        </div>
        <p class="size14 top10">请打开微信扫一扫登录</p>
        <div class="size14 top32 other-login">
          <p>其他登录方式</p>
          <input type="file" ref="fileEle" @input="submit"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {authCertificate} from '@/api/account/login.js'

export default {
  props: {
    url: {
      type: String,
      default: null,
    },
    showMask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: 1,
      file: undefined,
      isEmpty: false
    }
  },
  methods: {
    submit() {
      if (this.$refs.fileEle.files.length === 0) {
        this.isEmpty = true;
        return;
      }
      this.isEmpty = false;
      const file = this.$refs.fileEle.files[0];
      let form = new FormData();
      form.append('file', file, file.name)
      authCertificate(form).then((res) => {
        //授权成功
        this.$store.commit('SET_TOKEN', res)
        this.$store.dispatch('getUserInfo').then(() => {
          this.$router.replace('/select')
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login-content {
  .left {
    width: 60vw;

    .banner {
      position: absolute;
      max-height: 440px;
      max-width: 740px;
      width: 60vw;
    }
  }

  .login-form {
    max-width: 400px;
    width: 50vw;
    height: 50vw;
    max-height: 440px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .code {
      position: relative;
      width: 180px;
      height: 180px;
      border-radius: 4px;
    }

    .mark {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      background: hsla(0, 0%, 100%, .96);
      border: 1px solid #ddd;
    }

    .btn-refresh {
      background: #f8cccf;
      width: 120px;
      padding: 6px 0;
      border-radius: 20px;
      color: #000;
      font-size: 14px;
      margin-top: 20px;
      cursor: pointer;
      text-align: center;
    }
  }

  .other-login {
    position: relative;

    p {
      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      cursor: pointer;
    }
  }
}
</style>
