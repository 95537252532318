<template>
  <div>
    <div class="avatar-group-wrap">
      <template v-for="(student, index) in item.studentEvaluationInfoVOList">
        <img
          :src="getAvatar(student)"
          alt=""
          v-if="index < 4"
          :key="index"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { getAvatar } from '@/utils/random.js'
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      getAvatar: getAvatar
    }
  }
}
</script>
<style lang="less" scoped>
.avatar-group-wrap {
  display: inline-flex;
  flex-flow: wrap-reverse;
  width: 120px;
  height: 120px;
  border: 4px solid #ffffff;
  border-radius: 44px;
  background: #dfe9ff;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);

  > img {
    width: 50%;
    height: 50%;
    vertical-align: middle;
  }
}
</style>