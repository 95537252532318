<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="600px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        <div>选择封面</div>
      </div>
      <div class="content">
        <div class="list">
          <img
            :src="item.iconUrl"
            class="img hover"
            v-for="(item,index) in list"
            :key="index"
            @click="selected(item)"
          >

        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () {
      },
    }
  },
  created () {
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
    },
    selected (item) {
      this.close()
      this.$emit('select', item)
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  padding-bottom: 48px;
  padding-top: 32px;
  height: 470px;
  overflow: auto;
  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    justify-content: center;
    padding: 0 40px;
    .img {
      display: block;
      width: 80px;
      height: 80px;
      border-radius: 60px;
      margin-bottom: 32px;
    }
  }
}
</style>
