import request from "@/utils/request";
import config from "@/settings.js";

export function getCateList() {
    return request({
        url: config.requestUrlModel + "/sch_habit/listByCat",
        method: "get",
        params: {
            habitType: 0,
            isCommon: 1,
        },
    });
}

export function getHabitById(params) {
    return request({
        url: config.requestUrlModel + "/sch_habit/listByCatId",
        method: "get",
        params,
    });
}
export function habitsRewardStudents(data) {
    return request({
        url: config.requestUrlModel +
            "/classroom/classRoomEvaluation/habitsRewardStudents",
        method: "post",
        data,
        submitOnce: true,
    });
}

export function habitsRewardGroups(data) {
    return request({
        url: config.requestUrlModel +
            "/classroom/classRoomEvaluation/habitsRewardGroups",
        method: "post",
        data,
        submitOnce: true,
    });
}

export function getSchEvaluationList(params) {
    return request({
        url: config.requestUrlModel + "/schEvaluation/list",
        method: "get",
        params,
    });
}

export function getSchEvaluationListIcon(params) {
    return request({
        url: config.requestUrlModel + "/schEvaluation/listIcon",
        method: "get",
        params,
    });
}

export function getSchEvaluationListCat(params) {
    return request({
        url: config.requestUrlModel + "/schEvaluation/listCat",
        method: "get",
        params,
    });
}

export function schEvaluationCreate(data) {
    return request({
        url: config.requestUrlModel + "/schEvaluation/create",
        method: "post",
        data,
        submitOnce: true,
    });
}

export function voiceEvaluation(data) {
    return request({
        url: config.requestUrlModel + "/classroom/classRoomEvaluation/voiceEvaluation",
        method: "post",
        data,
        submitOnce: true,
    });
}