<template>
  <div class="count-btn-wrap">
    <div :class="`icon-a` + (readOnly ? ' read-only' : '')" @click="add">+</div>
    <div class="num">{{this.value}}</div>
    <div :class="`icon-a` + (readOnly ? ' read-only' : '')" @click="reduce">-</div>
  </div>
</template>
<script>
export default {
  name: "MyCountBtn",
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 9
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    add() {
      let newVal = this.value + 1 > this.max ? this.min : this.value + 1;
      this.$emit('input', newVal);
    },
    reduce() {
      let newVal = this.value - 1 < this.min ? this.max : this.value - 1;
      this.$emit('input', newVal);
    }
  }
}
</script>
<style lang="less" scoped>
.count-btn-wrap {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;

  .num {
    font-size: 120px;
    line-height: 1.4;
  }

  .icon-a {
    width: 80px;
    height: 40px;
    margin: 0 auto;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 20px;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.read-only{
      opacity: 0;
    }
  }
}
</style>