<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="740px"
      height="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        <div>{{ nameString }}</div>
        <div
          v-if="customHabit"
          class="size16 color5 add-text hover"
          @click="showAddHabitModal = true"
        >新增</div>
      </div>
      <div class="content flex-column flex-center">
        <div class="evaluate-content flex-column">

          <div class="list">
            <habit-item
              v-for="(item,index) in habitList"
              :key="index"
              :item="item"
              :index="index"
              :selected="selectedIds.includes(item.habitId)"
              @select="selectHabit"
            ></habit-item>
          </div>
        </div>
        <div
          class="menu"
          v-if="!customHabit"
        >
          <div class="title color3 size16">
            <div
              class="color8 size12 more hover"
              @click="changeHabitType"
            >返回
            </div>
          </div>
          <div class="cate color3 size16">
            <div>
              <a-tabs
                @change="change"
                :default-active-key="currentIndex"
              >
                <a-tab-pane
                  v-for="(item,index) in cateList"
                  :key="index"
                  :tab="item.habitCatName"
                >
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </div>
        <div
          class="menu-btn color8 hover"
          v-else
          @click="changeHabitType"
        >学校库</div>

        <div class="bottom flex flex-column-center flex-space-between">
          <close
            @click="close()"
            class="close"
          ></close>
          <div class="flex flex-column-center">
            <div class="size16 color5 flex">
              <div>已选 {{ selectedIds.length }} 个评价标签,</div>
              <div
                class="hover blue"
                @click="onAddRemark"
              >添加备注</div>
            </div>
            <my-button
              class="left12"
              size="small3"
              @click="onEvaluate()"
            >发送评价
            </my-button>
          </div>
        </div>
      </div>
    </a-modal>
    <div
      v-if="showLoading"
      class="loading-wrap flex"
    >
      <EvaluateLoading :type="type" />
    </div>
    <remark-modal
      v-model="showRemarkModal"
      @evaluate="remarkAndEvaluate"
    ></remark-modal>
    <add-custom-habit-modal
      v-model="showAddHabitModal"
      @refresh="getCustomHabitList"
    ></add-custom-habit-modal>
  </div>
</template>
<script>
import EvaluateLoading from '@/components/loading/Evaluate.vue'
import MyButton from '@/components/mybutton/MyButton.vue'
import close from '@/components/close/Index.vue'
import {
  getCateList,
  getHabitById,
  habitsRewardStudents,
  habitsRewardGroups,
  getSchEvaluationList
} from '@/api/home/evaluate.js'
import HabitItem from './habitItem.vue'
import remarkModal
  from './remarkModal.vue'
import addCustomHabitModal from './addCustomHabitModal.vue'

export default {
  components: { close, MyButton, HabitItem, EvaluateLoading, remarkModal, addCustomHabitModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    studentList: {
      type: Array,
      default: null,
    },
    groupsList: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      showLoading: false,
      currentIndex: 0,
      cateList: [],
      habitList: [],
      form: {
        items: [],
        studentIds: [],
        groupIds: [],
        remark: ''
      },
      nameList: '',
      selectedIds: [],
      type: 1, //动画类型 1奖励 2批评
      habitTypes: [],
      showRemarkModal: false,
      showAddHabitModal: false,
      customHabit: true,
    }
  },
  watch: {
    studentList (val) {
      console.log(val)
      this.form.studentIds = val.map((item) => item.studentId)
    },
    groupsList (val) {
      this.form.groupIds = val.map((item) => item.groupId)
    },
    show (val) {
      if (val) {
        this.getCustomHabitList()
        this.getNameList()
        this.selectedIds = []
        this.habitTypes = []
        this.currentIndex = 0;
        this.customHabit = true
      }
    },
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () {
      },
    },
    nameString () {
      let str = null
      if (this.nameList.length > 3) {
        let list = this.nameList.slice(0, 3)
        list = list.join('、')
        str = `对${list}共${this.nameList.length}人发起评价`
      } else if (this.nameList.length > 0) {
        let list = this.nameList.join('、')
        str = `对${list}发起评价`
      }
      return str
    },
  },
  methods: {
    getCustomHabitList () {
      getSchEvaluationList().then(res => {
        this.habitList = res
      })
    },
    getNameList () {
      if (this.studentList.length > 0) {
        this.nameList = this.studentList.map((item) => item.studentName)
      } else {
        this.nameList = this.groupsList.map((item) => item.groupName)
      }
    },
    close () {
      this.show = false
      this.$emit('input', false)
      this.$emit('close')
    },
    onEvaluate () {
      if (this.selectedIds.length == 0) {
        return this.$message.error('请选择评价卡片！')
      }
      this.form.items = this.selectedIds.map((item) => {
        return {
          habitId: item,
          remark: ''
        }
      })
      const type = this.habitTypes.every(item => item == 2)
      this.type = type ? 2 : 1;

      if (this.form.studentIds.length > 0) {
        habitsRewardStudents(this.form).then(() => {
          this.showLoading = true;
          setTimeout(() => {
            this.$message.success('评价成功');
            this.showLoading = false;
            this.clearHabitStatus()
            this.close()
            this.$emit('refresh')
          }, 2000)
        })
      }

      if (this.form.groupIds.length > 0) {
        habitsRewardGroups(this.form).then(() => {
          this.showLoading = true;
          setTimeout(() => {
            this.$message.success('评价成功')
            this.showLoading = false;
            this.clearHabitStatus()
            this.close()
            this.$emit('refresh')
          }, 2000)
        })
      }
    },
    getCateList () {
      if (this.cateList.length == 0) {
        getCateList().then((res) => {
          this.cateList = res
          this.getHabitById()
        })
      } else {
        this.getHabitById()
      }
    },
    change (index) {
      this.currentIndex = index
      this.getHabitById()
    },
    getHabitById () {
      getHabitById({
        habitCatId: this.cateList[this.currentIndex].habitCatId,
      }).then((res) => {
        this.habitList = res
        this.clearHabitStatus()
      })
    },
    selectHabit (index, selectStatus) {
      const { habitId, habitType } = this.habitList[index]

      if (!selectStatus) {
        this.selectedIds.push(habitId)
        this.habitTypes.push(habitType)
      } else {
        const index = this.selectedIds.findIndex(item => habitId == item)
        const typeIndex = this.habitTypes.findIndex(item => habitType == item)
        this.selectedIds.splice(index, 1)
        this.habitTypes.splice(typeIndex, 1)
      }
    },
    clearHabitStatus () {
      this.habitList.map((item) => {
        item.checked = false
        return item
      })
    },
    onAddRemark () {
      if (this.selectedIds.length == 0) {
        return this.$message.error('请选择评价卡片！')
      }
      this.showRemarkModal = true
    },
    remarkAndEvaluate (remark) {
      this.form.remark = remark
      this.onEvaluate()
    },
    changeHabitType () {
      this.customHabit = !this.customHabit
      this.habitTypes = []
      this.selectedIds = [];
      this.currentIndex = 0;

      if (this.customHabit) {
        this.getCustomHabitList()
      } else {
        this.getCateList()
      }
    }
  },
}
</script>
<style lang="less" scoped>
.header {
  position: relative;
  .add-text {
    position: absolute;
    top: 0px;
    right: 50px;
  }
}

.content {
  position: relative;
  padding-bottom: 0px;

  .evaluate-content {
    width: 100%;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 0px;
  }

  .cate {
    position: relative;
    width: calc(100% - 100px);
    height: 70px;
    line-height: 70px;
    float: left;
    margin-left: -20px;
    margin-top: 10px;

    & span {
      display: inline-block;
      height: 70px;
      margin-right: 18px;
    }

    .active {
      color: #000 !important;
      box-shadow: inset 0px -4px 0px 0px #ffce2e;
    }
  }

  .close-cate {
    position: absolute;
    top: 0;
    right: -50px;
    width: 60px;
    padding: 15px 20px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    justify-content: center;
    padding: 20px 0 120px 0;
  }
}

.title {
  float: left;
  height: 70px;
  width: 100px;
  text-align: center;
  line-height: 50px;
}

.menu {
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 0px 0px;
  line-height: 70px;
  position: absolute;
  bottom: 40px;
  z-index: 99;
}

.menu-btn {
  width: 100px;
  height: 70px;
  background: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 0px 0px;
  font-size: 12px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  bottom: 40px;
  z-index: 99;
}

/deep/ .ant-tabs-bar {
  border-width: 0 !important;
}

/deep/ .ant-tabs-nav-container {
  font-size: 12px !important;
  line-height: 0.6 !important;
  color: rgba(0, 0, 0, 0.3) !important;
}

/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 18px 0 0 !important;
  padding: 12px 0 !important;
}

/deep/ .ant-tabs-nav .ant-tabs-tab:hover,
/deep/ .ant-tabs-nav .ant-tabs-tab-active {
  color: #000 !important;
}

/deep/ .ant-tabs-ink-bar {
  background-color: rgba(255, 206, 46, 1) !important;
}

.loading-wrap {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
</style>
