<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      :maskClosable="false"
      width="540px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        温馨提醒
      </div>
      <div class="content-wrap flex-column flex-center flex-column-center">
        <div class="content size20 color5 flex-column flex-column-center ">
          <div>下课时间到了！</div>
          <div>5分钟后不操作将自动下课~</div>
          <div
            class="top12 countdown"
            v-if="show"
          >
            <a-statistic-countdown
              format="m 分 s 秒"
              :value="deadline"
            />
          </div>
        </div>
        <div class="bottom flex flex-column-center flex-space-between">
          <my-button
            size="medium"
            @click="continueClass()"
          >继续上课</my-button>
          <my-button
            @click="finshClass()"
            size="medium"
            color="pink"
          >下课</my-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import MyButton from '../../../../components/mybutton/MyButton.vue'
import { extendedClassTime } from '@/api/home/class.js'
import {mapState} from "vuex";
export default {
  components: { MyButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      deadline: null,
      timer:null
    }
  },
  computed: {
    ...mapState({
      teacherId: state => state.user.userInfo.teacherId,
      courseId: state => state.user.userInfo.courseId
    }),
    show: {
      get () {
        return this.value
      },
      set () { },
    },
  },
  watch: {
    show (val) {
      if (val) {
        this.deadline = this.$store.getters.classEndTimestamp

        this.timer = setInterval(() => {
          console.log(this.deadline)
          if (Date.now() >= this.deadline) {
            console.log('完成了',this.deadline)
            clearInterval(this.timer)
            this.finshClass()
          }
        },1000)
      } else {
        clearInterval(this.timer)
      }
    },
  },
  methods: {
    close () {
      this.$emit('input', false)
    },
    finshClass () {
      console.log('完成了')
      const sk = this.teacherId + '-' + this.courseId + '-randomList';
      sessionStorage.removeItem(sk);
      this.$store.dispatch('finishClass').then(() => {
        this.$router.push('/rank')
      })
    },
    continueClass () {
      extendedClassTime({ times: 5 }).then(res => {
        this.$store.commit('UPDATE_CLASS_TIME', {
          classEndTime: res.afterClassTime,
          classEndTimestamp: res.getOutOfClassTimeStamp
        })

        this.$emit('continue')
        this.close()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content-wrap {
  position: relative;
  padding-top: 87px;
  padding-bottom: 180px;
  .content {
    line-height: 34px;
  }
  .bottom {
    padding: 20px 58px !important;
  }
}
.countdown {
  height: 36px;
}
</style>
