<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="740px"
      height="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        {{ title }}
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <div class="result-content flex-column flex-column-center ">
          <div
            class="list"
            v-if="studentResult.length > 0"
          >
            <div
              v-for="(item,index) in studentResult"
              :key="index"
              class="item flex flex-space-between flex-column-center"
            >
              <div class="left flex flex-column-center">
                <img
                  v-if="item.rank == 1"
                  src="@images/home/gold_medal.png"
                  class="sort-img"
                >
                <img
                  v-if="item.rank == 2"
                  src="@images/home/silver_medal.png"
                  class="sort-img"
                >
                <img
                  v-if="item.rank == 3"
                  src="@images/home/bronze_medal.png"
                  class="sort-img"
                >
                <div
                  v-if="item.rank > 3"
                  class="sort"
                >{{item.rank}}</div>
                <div class="avatar-img left16">
                  <img
                    :src="item.avatar"
                    class="avatar"
                  >
                </div>
                <div class="left12 size18 color8 bold">{{item.studentName}}</div>
              </div>
              <div class="right flex flex-column-center">
                <div class="size14 color10 flex flex-column-center">
                  <span>{{type == 1 ? "获" : "晋级"}}</span>
                  <span class="number">{{item.score}}</span>
                  <span>{{type == 1 ? "个赞" : "轮"}}</span>
                </div>
                <div
                  class="evaluate left12 hover"
                  @click="singleEvaluate(studentResult,item)"
                >评价</div>
              </div>
            </div>

          </div>
          <div
            class="list"
            v-else
          >
            <div
              v-for="(item,index) in groupResult"
              :key="index"
              class="item flex flex-space-between flex-column-center"
            >
              <div class="left flex flex-column-center">
                <img
                  v-if="item.rank == 1"
                  src="@images/home/gold_medal.png"
                  class="sort-img"
                >
                <img
                  v-if="item.rank == 2"
                  src="@images/home/silver_medal.png"
                  class="sort-img"
                >
                <img
                  v-if="item.rank == 3"
                  src="@images/home/bronze_medal.png"
                  class="sort-img"
                >
                <div
                  v-if="item.rank > 3"
                  class="sort"
                >{{item.rank}}</div>
                <div class="avatar-img group-img left16">
                  <group-image :item="item"></group-image>
                </div>
                <div class="left12 size18 color8 bold">{{item.groupName}}</div>
              </div>
              <div class="right flex flex-column-center">
                <div class="size14 color10 flex flex-column-center">
                  <span>{{type == 1 ? "获" : "晋级"}}</span>
                  <span class="number">{{item.score}}</span>
                  <span>{{type == 1 ? "个赞" : "轮"}}</span>
                </div>
                <div
                  class="evaluate left12 hover"
                  @click="singleEvaluate(groupResult,item)"
                >评价</div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom flex flex-column-center flex-center">
          <close
            @click="close()"
            class="close"
          ></close>
          <div class="submit flex flex-column-center flex-column">
            <my-button
              size="big"
              @click="onEvaluate"
            >统一评价
            </my-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import close from '@/components/close/Index.vue'
import MyButton from '@/components/mybutton/MyButton'
import { getSort } from '@/utils/common.js'
import groupImage from '@/components/group/GroupImage.vue'

export default {
  components: {
    close,
    MyButton,
    groupImage
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "1", //1积分赛 2晋级赛
    },
    studentList: {
      type: Array,
      default: null
    },
    groupList: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      studentResult: [],
      groupResult: []
    }
  },
  watch: {
    studentList (val) {
      let data = [...val]
      getSort(data)
      this.studentResult = data
    },
    groupList (val) {
      let data = [...val]
      getSort(data)
      this.groupResult = data
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () {
      },
    },
    title () {
      return this.type == 1
        ? '积分赛结果' : '淘汰赛结果'
    },
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
    },
    onEvaluate () {
      this.studentResult.map(val => {
        val.evaluate = true;
      })
      this.groupResult.map(val => {
        val.evaluate = true;
      })
      this.$emit('evaluate', this.studentResult, this.groupResult)
    },
    singleEvaluate (list, item) {
      list.map(val => {
        val.evaluate = false;
      })
      item.evaluate = true;
      this.$emit('evaluate', this.studentResult, this.groupResult)
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  padding-bottom: 80px;
}

.result-content {
  width: 100%;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px 70px;
}

.list {
  width: 100%;
  .item {
    margin-bottom: 4px;
    background: rgba(243, 244, 250, 0.3);
    border-radius: 8px;
    opacity: 0.96;
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 15px 32px;

    .avatar-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      -webkit-box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      border: 2px solid #ffffff;
      display: flex;
      justify-content: center;
      align-content: center;
      .avatar {
        width: 50px;
        height: 50px;
      }
    }

    .group-img {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0.4);
    }

    .evaluate {
      width: 60px;
      height: 40px;
      background: rgba(255, 206, 46, 0.3);
      border-radius: 22px;
      font-size: 18px;
      color: #000000;
      text-align: center;
      line-height: 40px;
    }

    .number {
      margin: 0 6px;
      width: 30px;
      height: 30px;
      background: #ffce2e;
      border-radius: 22px;
      font-size: 18px;
      display: block;
      text-align: center;
      line-height: 30px;
      overflow: hidden;
    }

    .sort-img {
      width: 40px;
      height: 40px;
    }

    .sort {
      width: 40px;
      height: 40px;
      background: rgba(255, 206, 46, 0.3);
      border-radius: 22px;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      color: #000000;
    }
  }
}

.close {
  position: absolute;
  left: 30px;
}
</style>
