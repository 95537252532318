import request from '@/utils/request'
import config from '@/settings.js'

export function getGroupList(params) {
    return request({
        url: config.requestUrlModel + '/classroom/classRoomEvaluation/getGroupEvaluationList',
        method: 'get',
        params
    })
}

export function getUnclassifiedList(params) {
    return request({
        url: config.requestUrlModel + '/classroom/classRoomEvaluation/getUnclassifiedList',
        method: 'get',
        params
    })
}

export function addGroup(data) {
    return request({
        url: config.requestUrlModel + '/classroom/classRoomEvaluation/addGroup',
        method: 'post',
        data,
        submitOnce: true
    })
}

export function delGroup(data) {
    return request({
        url: config.requestUrlModel + '/classroom/classRoomEvaluation/delGroup',
        method: 'post',
        data,
        submitOnce: true
    })
}

export function editGroup(data) {
    return request({
        url: config.requestUrlModel + '/classroom/classRoomEvaluation/editGroup',
        method: 'post',
        data,
        submitOnce: true
    })
}