<template>
  <div class="login-page flex-column">
    <my-header></my-header>
    <login-form :url="codeUrl" :showMask="isPass" @refresh="refreshQrCode"></login-form>
    <my-bottom></my-bottom>
  </div>
</template>
<script>
import myBottom from '../components/myBottom.vue'
import LoginForm from '../components/loginForm.vue'
import myHeader from '../components/myHeader.vue'
import { getLoginQrCode, checkLoginQrCode } from '@/api/account/login.js'
import QRcode from 'qrcode'
export default {
  components: { myBottom, myHeader, LoginForm },
  data () {
    return {
      codeUrl: null,
      loginType: 1,
      codeOptions: {
        type: 'image/png', //类型
        quality: 0.5, //图片质量A Number between 0 and 1
        width: 180, //高度
        height: 180, //宽度
        errorCorrectionLevel: 'L', //容错率
        margin: 1, //外边距
        color: {
          dark: '#000000', //前景色
          light: '#ffffff', //背景色
        },
      },
      form: {
        qrCode: '',
      },
      timer: null,
      isPass:false,
      validTime:60,
      validTimer:null
    }
  },
  created () {
    this.getCode()
    this.checkStatus()
  },
  methods: {
    getCode () {
      getLoginQrCode().then((res) => {
        this.isPass = false
        this.validTime = 60;
        this.form.qrCode = res.qrCode
        const env = process.env.NODE_ENV
        const data = env == 'production' ? res.qrCodeUrl : this.form.qrCode
        QRcode.toDataURL(data, this.codeOptions).then((imgData) => {
          this.codeUrl = imgData
        })

        this.validTimer = setInterval(() => {
          if (this.validTime === 0) {
            this.isPass = true;
            clearInterval(this.validTimer)
          } else {
              this.validTime -= 1;
          }
        },1000)
      })
    },
    checkStatus () {
      this.timer = setInterval(() => {
        checkLoginQrCode(this.form).then((res) => {
          if (res.status == 1) {
            //授权成功
            this.$store.commit('SET_TOKEN', res.token)
            clearInterval(this.timer)
            clearInterval(this.validTimer)
            this.timer = null
            this.$store.dispatch('getUserInfo').then(() => {
              this.$router.replace('/select')
            })
          }
        })
      }, 3000)
    },
    refreshQrCode(){
      this.getCode()
    }
  },
}
</script>
<style scoped lang="less">
.login-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(360deg, #edf4ff 0%, #faf6f5 100%);
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>
