//截取字符串
export function subText(string, len) {
    if (string.length > len) {
        return string.substr(0, len - 1) + "...";
    } else {
        return string;
    }
}

//获取前三位
export function getTopThree(arr) {
    if (arr === null) return [];
    const length = arr.length >= 3 ? 3 : arr.length;
    return arr.slice(0, length);
}

//获取当前url的参数
export function getParams(name) {
    const getUrl = window.location.href; //获取当前url
    let qyInfo = getUrl.split("?");
    if (qyInfo.length < 2) {
        return "";
    }
    let params = qyInfo[1].split("&");
    for (let i = 0; i < params.length; i++) {
        let item = params[i].split("=");
        let key = item[0];
        let value = item[1];
        if (key === name) {
            return value;
        }
    }
    return "";
}

//根据分数排名次
export function getSort(data) {
    data.sort((a, b) => {
        return b.score - a.score;
    });

    let prescore;
    let rank = 0;
    data.forEach((item) => {
        if (item.score == prescore) {
            item.rank = rank;
        } else {
            rank++;
            prescore = item.score;
            item.rank = rank;
        }
    });
}