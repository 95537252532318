<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    console.log('启动')
    if (this.$store.getters.token) {
      this.$store.dispatch('getUserInfo')
    }
  }
}
</script>

<style>
</style>
