<template>
  <div class="student">
    <div class="student-list">
      <div class="list flex">
        <div
          class="item"
          v-for="(item,i) in list"
          :key="i"
        >
          <Student
            :student="item"
            :show-checked="index !== 0"
            @change="selectStudent"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getStudentEvaluationList } from '@/api/home/class.js'
import { getAvatar } from '@/utils/random.js'
import Student from '@/components/student/Student'

export default {
  props: ['index'],
  components: { Student },
  data () {
    return {
      list: [],
      userInfo: this.$store.getters.userInfo,
      currIndex: [],
      sort: 1
    }
  },
  mounted () {
    this.getStudentList()
    this.$root.$on("checkAll", () => {
      console.log('全选')
      this.list.forEach((item, index) => {
        this.$set(this.list, index, { ...item, checked: true });
      });
      this.$emit('change', this.list)
      console.log(this.list)
    });
    this.$root.$on("sortList", (value) => {
      this.sort = value
      this.getStudentList()
    })
  },
  methods: {
    selectStudent (item) {
      if (this.index === 0) {
        this.list.map((item) => {
          item.checked = false
          return item
        })
        item.checked = !item.checked
        this.$emit('change', this.list)
        this.$emit('evaluate')
      } else {
        item.checked = !item.checked
        this.$emit('change', this.list)
      }
    },
    getStudentList () {
      this.list = []
      getStudentEvaluationList({
        classId: this.userInfo.classId,
        sort:this.sort,
      }).then((res) => {
        this.list = res
        this.list.map((item) => {
          item.checked = false
          item.avatar = getAvatar(item)
          return item
        })
        this.$emit('change', this.list)
      })
    },
  },
}
</script>
<style lang="less" scoped>
.student {
  width: 100%;
}

.student-list {
  width: 100%;
  padding: 72px 3vw 200px 3vw;
  display: flex;
  flex-direction: column;
  align-content: center;

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    justify-content: center;

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      // width: 180px;
      margin-bottom: 24px;

      .avatar {
        position: relative;
        top: 0;
        left: 0;
        width: 120px;
        height: 120px;
        border-radius: 44px;
        border: 6px solid #ffffff;
        overflow: hidden;

        .avatar-img {
          position: absolute;
          left: -6px;
          top: -6px;
          width: 120px;
        }
      }

      .name {
        position: absolute;
        right: 0;
        top: -10px;
        background: #ffeecd;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
        border-radius: 22px;
        border: 2px solid #ffffff;
        padding: 0 8px;
        font-size: 16px;
        color: #000000;
        line-height: 30px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      }

      .count {
        width: 120px;
        height: 44px;
        position: absolute;
        bottom: -17px;
        background: #ffeecd;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
        border-radius: 60px;
        border: 4px solid #ffffff;
        line-height: 36px;
        overflow: hidden;
        z-index: 100;

        .add {
          background-color: rgba(255, 206, 46, 1);
          height: 100%;
        }

        .subtract {
          background-color: rgba(191, 196, 255, 1);
          height: 100%;
        }
      }

      .img {
        width: 38px;
      }

      .img1 {
        position: absolute;
        left: -2px;
        bottom: 21px;
        z-index: 99;
      }

      .img2 {
        position: absolute;
        left: 16px;
        bottom: 14px;
        z-index: 99;
      }

      .img3 {
        position: absolute;
        left: 35px;
        bottom: 14px;
        z-index: 99;
      }

      .img4 {
        position: absolute;
        left: 52px;
        bottom: 14px;
        z-index: 99;
      }

      .img5 {
        position: absolute;
        right: 7px;
        bottom: 18px;
        z-index: 99;
      }

      .img6 {
        position: absolute;
        right: -6px;
        bottom: 20px;
        z-index: 99;
      }
    }

    .selected {
      position: absolute;
      left: 0;
      top: 61px;
      z-index: 102;
      width: 120px;
      height: 79px;
      background: #ff8c29;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
      border-radius: 22px;
      border: 4px solid #ffffff;

      .selected-img {
        width: 71px;
        margin-top: 10px;
        margin-left: 24px;
      }
    }

    .item:hover {
      cursor: pointer;
    }
  }
}
</style>
