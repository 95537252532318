<template>
  <div class="rank-page flex-column">
    <my-header></my-header>
    <rank-list></rank-list>
    <my-bottom></my-bottom>
  </div>
</template>
<script>
import myHeader from '../components/myHeader.vue'
import myBottom from '../components/myBottom.vue'
import RankList from '../components/rankList.vue'

export default {
  components: { myBottom, myHeader, RankList },
}
</script>
<style lang="less" scoped>
.rank-page {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(360deg, #edf4ff 0%, #faf6f5 100%);
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow: hidden;
}
</style>
