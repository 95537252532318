<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="740px"
      height="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        班级实力榜
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <div class="rank-wrap">
          <div class="habit-wrap">
            <div class="habit-head">
              <div>
                评价：<span class="amount">{{ amount }}</span>次
              </div>
              <div class="group flex size16 color5">
                <div
                  @click="changeHabitType(index)"
                  v-for="(item,index) in habitTypeArr"
                  :key="index"
                  class="group-item"
                  :class="{'active' : index === currHabitTypeIndex}"
                >{{ item }}
                </div>
              </div>
            </div>
            <div class="table-wrap">
              <table class="table-rank">
                <thead>
                  <tr>
                    <th width="50px">排行</th>
                    <th>评价标签</th>
                    <th width="80px">次数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="habitList.length === 0">
                    <td></td>
                    <td>暂无记录</td>
                    <td></td>
                  </tr>
                  <tr
                    v-for="(item, index) in habitList"
                    :key="index"
                  >
                    <td>{{ item.rank }}</td>
                    <td>{{ item.habitName }}</td>
                    <td>{{ item.rewardNums }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="student-wrap table-wrap">
            <table class="table-rank">
              <thead>
                <tr>
                  <th width="60px">排行</th>
                  <th width="150px">学生姓名</th>
                  <th>综合累计</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="studentList.length === 0">
                  <td></td>
                  <td>暂无记录</td>
                  <td></td>
                </tr>
                <tr
                  v-for="(item, index) in studentList"
                  :key="index"
                >
                  <td>
                    <div class="rank">{{item.rank}}</div>
                  </td>
                  <td class="td-student-wrap">
                    <div class="avatar-wrap">
                      <img
                        :src="getAvatar(item)"
                        alt=""
                      />
                    </div>
                    {{ item.studentName }}
                  </td>
                  <td>获得{{ item.evaluateNums }}次评价，累计<span class="gAmount">{{ item.growthValue }}</span>成长值</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bottom flex flex-column-center flex-center">
          <close
            @click="close()"
            class="close"
          ></close>
          <div class="group flex size16 color5">
            <div
              @click="changeDateType(index)"
              v-for="(item,index) in dateTypeArr"
              :key="index"
              class="group-item"
              :class="{'active' : index === currDateIndex}"
            >{{ item }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import close from '@/components/close/Index.vue'
import { getPowerRank } from '@/api/home/class.js'
import { getAvatar } from '@/utils/random.js'
import { mapState } from "vuex";

export default {
  components: {
    close,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      currDateIndex: 0,
      currHabitTypeIndex: 0,
      dateTypeArr: ['今天', '本月', '本学期'],//0今天，1本月，2本学期
      habitTypeArr: ['表扬', '待改进'],
      amount: 0,
      habitList: [],
      studentList: [],
      getAvatar: getAvatar
    }
  },
  computed: {
    ...mapState({
      classId: state => state.user.userInfo.classId,
    }),
    show: {
      get () {
        return this.value
      },
      set () {
      },
    }
  },
  watch: {
    show (newVal) {
      if (newVal) {
        this.init()
      }
    }
  },
  mounted () {
  },
  methods: {
    init () {
      this.currDateIndex = 0
      this.currHabitTypeIndex = 0
      this.getData();
    },
    getData () {
      getPowerRank({
        classId: this.classId,
        dateType: this.currDateIndex,
        habitType: this.currHabitTypeIndex + 1
      }).then((res) => {
        this.habitList = res.evaluateListVOS;
        this.studentList = res.classGrowthRankListVOS;
        this.amount = res.evaluateNums;
      })
    },
    close () {
      this.show = false
      this.$emit('input', false)
      this.showRandomList = false
    },
    changeHabitType (index) {
      this.currHabitTypeIndex = index;
      this.getData();
    },
    changeDateType (index) {
      this.currDateIndex = index;
      this.getData();
    },
    refresh () {
      setTimeout(() => {
        this.$emit('refresh')
      }, 1000)
      this.close()
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  padding-top: 20px;
  padding-bottom: 100px;
}

.count {
  background: rgba(255, 206, 46, 0.3);
  border-radius: 20px;
  width: 270px;
  height: 100px;
}

.close {
  position: absolute;
  left: 30px;
}

.again:hover {
  cursor: pointer;
}

.group {
  background: #f1f7ff;
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  .group-item {
    padding: 8px 18px;
  }

  .group-item:hover {
    cursor: pointer;
  }

  .group-item.active {
    background-color: rgba(255, 206, 46, 1);
    color: #000;
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.rank-wrap {
  display: flex;
  width: 100%;
  padding: 0 20px;

  .habit-wrap {
    flex: 40% 0 0;
    margin-right: 10px;
    display: flex;
    flex-flow: column;

    .habit-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .amount {
        font-weight: bold;
      }
    }
  }

  .table-wrap {
    border: 1px solid #eee;
    border-radius: 10px;
    height: 330px;
    overflow: auto;
  }

  .table-rank {
    width: 100%;
    border-spacing: 0;
    position: relative;

    th {
      background: rgba(255, 206, 46, 0.3);
      text-align: center;
      border-bottom: 1px solid #eee;
      height: 32px;
    }

    td {
      text-align: center;
      border-bottom: 1px solid #eee;
      height: 40px;
    }

    tr:last-child td {
      border-bottom: none;
    }

    .td-student-wrap {
      display: flex;
      align-items: center;
      height: 80px;
    }

    .avatar-wrap {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      border: 2px solid #ffffff;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .student-wrap {
    flex: 1;
    height: 380px;
  }
}

.gAmount {
  background: #ffce2e;
  color: #000000;
  padding: 2px 6px;
  border-radius: 10px;
}

.rank {
  background: rgba(255, 206, 46, 0.3);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  margin: 0 auto;
}
</style>
