<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="740px"
      height="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        <div>淘汰赛</div>
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <div class="race-content flex-column flex-column-center">
          <div class="pk-bg">第{{round}}轮</div>
          <div
            class="list top24"
            v-if="studentData.length > 0"
          >
            <div
              class="item"
              v-for="(item,index) in studentData"
              :key="index"
              :class="{'bg' : index % 2 != 0 ,'fail-bg' :item.status === false}"
              v-if="item.nextRound"
            >
              <div
                class="status sucess"
                v-if="item.status === true"
              >
                <img src="@images/home/icon_success.png">
              </div>
              <div
                class="status fail"
                v-if="item.status === false"
              >
                <img src="@images/home/icon_fail.png">
              </div>
              <div class="avatar-img">
                <img :src="item.avatar">
              </div>
              <div class="name size16 color10">{{item.studentName}}</div>
              <div class="top16 flex btn-list">
                <button @click="onMark(item,true,1)">晋级</button>
                <button
                  @click="onMark(item,false,0)"
                  class="left12"
                >淘汰</button>
              </div>
            </div>
          </div>
          <div
            class="list top24"
            v-else
          >
            <div
              class="item"
              v-for="(item,index) in groupData"
              :key="index"
              :class="{'bg' : index % 2 != 0 ,'fail-bg' :item.status === false}"
              v-if="item.nextRound"
            >
              <div
                class="status sucess"
                v-if="item.status === true"
              >
                <img src="@images/home/icon_success.png">
              </div>
              <div
                class="status fail"
                v-if="item.status === false"
              >
                <img src="@images/home/icon_fail.png">
              </div>
              <div class="avatar-img flex flex-center flex-column-center">
                <group-image :item="item"></group-image>
              </div>
              <div class="name size16 color10">{{item.groupName}}</div>
              <div class="top16 flex btn-list">
                <button @click="onMark(item,true,1)">晋级</button>
                <button
                  @click="onMark(item,false,0)"
                  class="left12"
                >淘汰</button>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom flex flex-column-center flex-center">
          <close
            @click="close()"
            class="close"
          ></close>
          <div class="submit flex flex-center">
            <my-button
              size="small3"
              @click="createResult"
            >生成比赛结果</my-button>
            <my-button
              size="small3"
              @click="onNext"
              class="left20"
            >进入下一轮</my-button>
          </div>
          <div
            class="reset blue hover"
            @click="refresh"
          >重置比赛</div>
        </div>
      </div>
    </a-modal>
    <result-modal
      v-model="showResult"
      type="2"
      :studentList="studentResult"
      :groupList="groupResult"
      @evaluate="onEvaluate"
    ></result-modal>
    <evaluate-student-modal
      :studentList="selectedStudent"
      :groupsList="selectedGroup"
      v-model="showEvaluate"
      @refresh="refresh"
    ></evaluate-student-modal>
  </div>
</template>
<script>
import close from '@/components/close/Index.vue'
import groupImage from '@/components/group/GroupImage.vue'
import MyButton from '@/components/mybutton/MyButton.vue'
import resultModal from '@/view/home/components/race/resultModal'
import EvaluateStudentModal from '../evaluate/evaluateStudentModal.vue'

export default {
  components: { close, groupImage, MyButton, resultModal, EvaluateStudentModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    studentList: {
      type: Array,
      default: null
    },
    groupList: {
      type: Array,
      default: null
    }
  },
  watch: {
    studentList (val) {
      val.map(item => {
        item.score = 0
        item.rank = 0
        item.evaluate = false
        item.nextRound = true
        item.showStatus = null
        return item
      })
      this.round = 1;
      this.studentData = val
    },
    groupList (val) {
      val.map(item => {
        item.score = 0
        item.rank = 0
        item.evaluate = false
        item.nextRound = true
        item.showStatus = null
        return item
      })
      this.round = 1;
      this.groupData = val
    }
  },
  data () {
    return {
      studentData: [],
      groupData: [],
      currentIndex: "",
      allowClick: true,
      showResult: false,
      studentResult: [],
      groupResult: [],
      showEvaluate: false,
      selectedStudent: [],
      selectedGroup: [],
      round: 1
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () {
      },
    },
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
      this.$emit('close')
    },
    createResult () {
      this.close()
      this.studentData.map(item => {
        item.status = null
        return item
      })
      this.groupData.map(item => {
        item.status = null
        return item
      })
      this.studentResult = [...this.studentData]
      this.groupResult = [...this.groupData]
      this.showResult = true;
    },
    refresh () {
      setTimeout(() => {
        this.$emit('refresh')
      }, 100)
      this.close()
    },
    onEvaluate (studentList, groupList) {
      console.log(1111, studentList)
      console.log(222, groupList)
      this.selectedStudent = studentList.filter(item => item.evaluate)
      this.selectedGroup = groupList.filter(item => item.evaluate)

      this.showEvaluate = true
    },
    onMark (item, value, score) {
      this.$forceUpdate();
      item.status = value
      item.score += score
    },
    onNext () {
      this.$forceUpdate()
      if (this.studentList.length > 0) {
        this.studentList.map(item => {
          item.nextRound = item.status ? true : false
          item.status = null
          return item;
        })
      } else {
        this.groupList.map(item => {
          item.nextRound = item.status
          item.status = null
          return item;
        })
      }
      this.round += 1;
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  position: relative;
  .add-text {
    position: absolute;
    top: 0px;
    right: 50px;
  }
}

.content {
  position: relative;
  padding-bottom: 80px;

  .race-content {
    width: 100%;
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 32px 0px;

    .pk-bg {
      width: 208px;
      height: 62px;
      font-size: 24px;
      color: #ffffff;
      text-align: center;
      background-image: url("../../../../assets/images/home/pk_bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      line-height: 62px;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .bg {
        background: rgba(46, 137, 255, 0.15) !important;
      }

      .fail-bg {
        background: rgba(0, 0, 0, 0.08) !important;
        .name {
          color: rgba(0, 0, 0, 0.3) !important;
        }
      }

      .item {
        position: relative;
        width: 164px;
        background: rgba(46, 66, 255, 0.15);
        padding: 24px 0;
        border-radius: 20px;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 6px;
        margin-left: 6px;

        .avatar-img {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          background: rgba(223, 233, 255, 1);
          border: 2px solid #ffffff;
          overflow: hidden;

          & img {
            width: 100%;
          }
        }

        .status {
          position: absolute;
          top: 4px;
          left: 4px;
          width: 56px;
          height: 56px;
          background: #ffce2e;
          box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
          border-radius: 60px;
          border: 2px solid #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .sucess {
          & img {
            width: 43px;
            height: 31px;
          }
        }

        .fail {
          background: #9a8c8c !important;
          box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1) !important;
          & img {
            width: 40px;
            height: 40px;
          }
        }

        .name {
          width: 120px;
          height: 40px;
          background: #ffeecd;
          box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
          border-radius: 60px;
          border: 4px solid #ffffff;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.7);
          font-weight: 500;
          text-align: center;
          line-height: 32px;
          margin-top: -14px;
        }

        .btn-list {
          & button {
            width: 50px;
            height: 40px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
        }
      }
    }
  }
}

.close {
  position: absolute;
  left: 30px;
}

.reset {
  position: absolute;
  right: 40px;
}
</style>
