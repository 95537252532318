import * as types from "../mutation-types";
import { getUserInfo, finishClass } from "@/api/account/user.js";
import { getRewardRank, getGroupRewardRank } from "@/api/home/class.js";

import { getTopThree } from "@/utils/common.js";

const state = {
    token: "",
    userInfo: {
        //用户信息
        classId: "",
        courseId: "",
        teacherId: "",
    },
    currentClassName: "", //当前班级名字
    rank: null,
};

const getters = {
    currentUser: (state) => state,
};

const actions = {
    //获取教师信息
    getUserInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getUserInfo()
                .then((result) => {
                    commit("CREATE_USER", result);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    //下课
    finishClass({ commit, state }) {
        return new Promise((resolve, reject) => {
            const data = {
                classId: state.userInfo.classId,
                type: 1,
            };
            getRewardRank(data).then((personageRes) => {
                getGroupRewardRank(data).then((groupRes) => {
                    commit("SET_RANK", {
                        personage: getTopThree(personageRes),
                        group: getTopThree(groupRes),
                    });
                    finishClass({
                            classId: state.userInfo.classId,
                        })
                        .then((result) => {
                            commit("REMOVE_USER");
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            });
        });
    },
    //退出
    logout({ commit }) {
        commit("REMOVE_USER");
    },
};

const mutations = {
    [types.SET_TOKEN](state, value) {
        state.token = value;
    },
    [types.CREATE_USER](state, value) {
        console.log(value);
        value.teacherName = value.teacherName.replace("老师", "") + "老师";
        value.courseName = value.courseName + "课";
        state.userInfo = value;
    },
    [types.REMOVE_USER](state) {
        state.token = "";
        state.userInfo = "";
    },
    [types.SET_CLASSNAME](state, value) {
        state.currentClassName = value;
    },
    [types.SET_RANK](state, value) {
        state.rank = value;
    },
    [types.UPDATE_CLASS_TIME](state, value) {
        state.userInfo.classEndTime = value.classEndTime;
        state.userInfo.classEndTimestamp = value.classEndTimestamp;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};