import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import { Modal, notification, message } from "ant-design-vue";
import VConsole from 'vconsole'

message.config({
    top: `42vh`,
});

import "animate.css";
import "@assets/css/base.css";
import "@assets/css/fontsize.css";
import "@assets/css/fontcolor.css";
import "@assets/css/ant.css";
import "@assets/css/button.css";
import "@assets/css/margin.css";
import "normalize.css";
import "ant-design-vue/dist/antd.css";
import "@/permission";
Vue.config.productionTip = false;
Vue.prototype.$Tnotification = notification;
Vue.prototype.$Tmodal = Modal;
Vue.prototype.$Tmessage = message;
Vue.use(Antd);

if (process.env.NODE_ENV != 'production'){
    const vConsole = new VConsole()
    Vue.use(vConsole)
}

new Vue({
    router,
    store,
    render: (h) => h(App),
    beforeCreate() {
        Vue.prototype.$root = this
    }
}).$mount("#app");
