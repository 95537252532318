<template>
  <div>
    <div
      @click="onRecord"
      class="record-btn"
      @touchstart="handleMouseDown"
      @touchend="handleMouseUp"
      @contextmenu="preventDefaultContextMenu"
      ref="myElement"
      v-if="isAndroid"
    >
      <img
        src="@images/home/icon_cup.png"
        class="cup"
      >
      <div class="size14 top4 div-center">音评</div>
    </div>
    <div
      @click="onRecord"
      class="record-btn"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      ref="myElement"
      v-else
    >
      <img
        src="@images/home/icon_cup.png"
        class="cup"
      >
      <div class="size14 top4 div-center">音评</div>
    </div>
    <div
      class="record-tips"
      v-show="showTips"
    >
      <div class="bubble">
        <div class="size20 color10"><span class="active">给</span> 王小明 <span class="active">发</span> 认真听讲 <span class="active">的评价</span> </div>
      </div>
      <div class="color8 top10">按住“音评”可以这样说</div>
    </div>
    <div
      class="record-text"
      v-show="recordStatus"
    >
      <div class="record-content">
        <div class="text size16 color8">{{recordText}}</div>
      </div>
    </div>
    <div
      v-if="showLoading"
      class="loading-wrap flex"
    >
      <EvaluateLoading />
    </div>
  </div>
</template>
<script>
import { voiceEvaluation } from '@/api/home/evaluate.js'
import EvaluateLoading from '@/components/loading/Evaluate.vue'
export default {
  data () {
    return {
      showTips: false,
      recordStatus: false,
      recordText: "",
      mouseTimer: null,
      recordTime: 5,
      recordTimer: null,
      recorder: "",
      status: "UNDEFINED", // "UNDEFINED" "CONNECTING" "OPEN" "CLOSING" "CLOSED"
      iatWS: "",
      resultText: "",
      countdownInterval: "",
      isRecordSucess: false,
      showLoading: false,
      isAndroid: false
    }
  },
  components: {
    EvaluateLoading
  },
  created () {
    console.log('userAgent',navigator.userAgent)
    if (/Android/i.test(navigator.userAgent)) {
      this.isAndroid = true
    }
    console.log(this.isAndroid)
  },
  mounted () {
    this.recorder = new RecorderManager("/lib/rta");
    this.recorder.onStart = () => {
      this.changeStatus("OPEN");
      clearInterval(this.recordTimer);
      this.recordTime = 5;
      this.isRecordSucess = false
      this.recordText = "录音中（" + this.recordTime + "s）";
      this.recordStatus = true
      this.recordTimer = setInterval(() => {
        this.recordTime -= 1;
        if (this.recordTime == 0) {
          clearInterval(this.recordTimer);
          this.isRecordSucess = true;
          this.recorder.stop();
        } else {
          this.recordText = "录音中（" + this.recordTime + "s）";
        }
      }, 1000)
    };

    this.recorder.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
      if (this.iatWS.readyState === this.iatWS.OPEN) {
        this.iatWS.send(new Int8Array(frameBuffer));
        if (isLastFrame) {
          this.iatWS.send('{"end": true}');
          this.changeStatus("CLOSING");
        }
      }
    };
    this.recorder.onStop = () => {
      clearInterval(this.countdownInterval);
      if (this.status == 'CLOSED' && this.isRecordSucess) {
        this.onEvaluateStudent()
      }
    };
  },
  methods: {
    preventDefaultContextMenu(event) {
      event.preventDefault();
    },
    connectWebSocket () {
      const websocketUrl = this.getWebSocketUrl();
      if ("WebSocket" in window) {
        this.iatWS = new WebSocket(websocketUrl);
      } else if ("MozWebSocket" in window) {
        this.iatWS = new MozWebSocket(websocketUrl);
      } else {
        alert("浏览器不支持WebSocket");
        return;
      }
      this.changeStatus("CONNECTING");
      this.iatWS.onopen = (e) => {
        // 开始录音
        this.recorder.start({
          sampleRate: 16000,
          frameSize: 1280,
        });
      };
      this.iatWS.onmessage = (e) => {
        this.renderResult(e.data);
      };
      this.iatWS.onerror = (e) => {
        console.error(e);
        this.recorder.stop();
        this.changeStatus("CLOSED");
      };
      this.iatWS.onclose = (e) => {
        this.recorder.stop();
        this.changeStatus("CLOSED");
      };
    },
    renderResult (resultData) {
      let jsonData = JSON.parse(resultData);
      if (jsonData.action == "started") {
        // 握手成功
        console.log("握手成功");
      } else if (jsonData.action == "result") {
        const data = JSON.parse(jsonData.data);
        console.log(data);
        // 转写结果
        let resultTextTemp = "";
        data.cn.st.rt.forEach((j) => {
          j.ws.forEach((k) => {
            k.cw.forEach((l) => {
              resultTextTemp += l.w;
            });
          });
        });
        if (data.cn.st.type == 0) {
          // 【最终】识别结果：
          this.resultText += resultTextTemp;
          resultTextTemp = "";
        }
        console.log(this.resultText)
      } else if (jsonData.action == "error") {
        // 连接发生错误
        console.log("出错了:", jsonData);
        // if (jsonData.code === '10700') {
        //   this.$message.error('请检查浏览器麦克风权限是否开启')
        // }
      }
    },
    changeStatus (status) {
      this.status = status;
      if (status === "CONNECTING") {
        console.log("建立连接中")
        this.resultText = "";
      } else if (status === "OPEN") {
        console.log("录音中")
      } else if (status === "CLOSING") {
        console.log("处理中")
        this.recordStatus = true
        this.recordText = "处理中···";
        console.log("关闭连接中")
      } else if (status === "CLOSED") {
        console.log("关闭")
      }
    },
    getWebSocketUrl () {
      var url = "wss://rtasr.xfyun.cn/v1/ws";
      var appId = "ce0044a6";
      var secretKey = "8c2e6804a39520cfeea484a1826c5491";
      var ts = Math.floor(new Date().getTime() / 1000);
      var signa = hex_md5(appId + ts);
      var signatureSha = CryptoJSNew.HmacSHA1(signa, secretKey);
      var signature = CryptoJS.enc.Base64.stringify(signatureSha);
      signature = encodeURIComponent(signature);
      return `${url}?appid=${appId}&ts=${ts}&signa=${signature}`;
    },
    onRecord () {
      if (!this.recordStatus) {
        this.showTips = !this.showTips
      }
    },
    handleMouseDown () {
      this.mouseTimer = setTimeout(() => {
        this.handleLongPress();

        this.$refs.myElement.removeEventListener('contextmenu', this.preventDefaultContextMenu);
      }, 500);
    },
    handleMouseUp () {
      console.log(11)
      clearTimeout(this.mouseTimer);

      if (this.recordStatus) {
        console.log('离开')
        clearInterval(this.recordTimer);
        this.isRecordSucess = true;
        this.recorder.stop();
      }
    },
    handleLongPress () {
      if (this.recordTimer) {
        return this.$message.error('正在录音中...')
      }
      this.connectWebSocket();
    },
    onEvaluateStudent () {
      voiceEvaluation({
        "text": this.resultText
        // "text": "奖励蔡文彬同学,拾金不昧卡片"
        // "text":"给蔡文彬发批评"
      }).then(res => {
        this.recordStatus = false;
        this.showLoading = true;
        setTimeout(() => {
          this.$message.success('评价成功')
          this.$emit('refresh');
          this.showLoading = false;
          this.recordTimer = null
        }, 2000)
      }).catch(res => {
        console.log('评价失败')
        console.log(res)
        this.recordStatus = false;
        this.recordTimer = null
        this.$message.error(res.msg)
        if (res.code == 7201) {
          console.log('面板')
          this.$emit('evaluate', res.data)
        }
      })
    }
  },
  beforeDestroy() {
    this.$refs.myElement.removeEventListener('contextmenu', this.preventDefaultContextMenu);
  }
}
</script>
<style lang="less" scoped>
.record-btn {
  position: fixed;
  right: 0;
  bottom: calc(50vh - 200px);
  z-index: 99;
  background: #ffce2e;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px 0px 0px 20px;
  border: 2px solid #ffffff;
  padding: 30px 8px 22px 12px;
  height: 116px;
  .cup {
    width: 40px;
    pointer-events:none;
  }
}

.record-btn:hover {
  cursor: pointer;
}
.record-tips {
  position: fixed;
  right: 80px;
  bottom: calc(50vh - 200px);
  z-index: 99;
  background: #ffce2e;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: 2px solid #ffffff;
  padding: 15px;
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.bubble {
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  margin-bottom: 10px;
  padding: 5px 22px;
  &::before {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-color: transparent #fff;
    border-width: 0 10px 10px 0;
    border-style: solid;
  }
}

.record-text {
  position: fixed;
  right: 10px;
  bottom: calc(50vh - 90px);
  z-index: 99;
  .record-content {
    position: relative;
    width: 160px;
    height: 50px;
    background: #88f1bc;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    text-align: center;
    line-height: 50px;
    &::before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 20px;
      width: 0;
      height: 0;
      border-color: transparent #88f1bc;
      border-width: 0 10px 10px 0;
      border-style: solid;
    }
  }
  .error {
    background: #ffb0bc !important;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2) !important;
    &::before {
      border-color: transparent #ffb0bc !important;
    }
  }
}

.active {
  color: rgba(255, 0, 0, 1);
}

.loading-wrap {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
</style>
