<template>
  <div>
    <div
      v-if="value"
      class="open animate__animated animate__slideInRight"
      :class="{'animate__slideOutRight':closeAnimation}"
    >
      <div class="open-content">
        <div class="content">
          <div class="header">
            <img src="@assets/images/home/award.png">
          </div>
          <div
            class="award-list list"
            v-if="list"
          >
            <a-collapse
              :expand-icon-position="expandIconPosition"
              :bordered="false"
            >
              <a-collapse-panel
                v-for="(item,index) in list"
                :key="index + 1"
              >
                <template slot="header">
                  <div class="flex flex-column-center flex-space-between">
                    <div class="flex flex-column-center">
                      <div
                        v-if="index > 2"
                        class="sort icon40 size18"
                      >{{index+1}}</div>
                      <img
                        v-if="index == 0"
                        src="@images/home/gold_medal.png"
                        class="icon40"
                      >
                      <img
                        v-if="index == 1"
                        src="@images/home/silver_medal.png"
                        class="icon40"
                      >
                      <img
                        v-if="index == 2"
                        src="@images/home/bronze_medal.png"
                        class="icon40"
                      >
                      <div
                        v-if="currentIndex == 0 && item.studentEvaluationInfoVO"
                        class="left16"
                      >
                        <span class="size18">{{item.studentEvaluationInfoVO.studentName}} </span>
                        <span class="size14">{{item.studentEvaluationInfoVO.schoolNum.substr(-4,4) }}</span>
                      </div>
                      <div
                        v-if="currentIndex == 1"
                        class="left16"
                      >
                        <span
                          class="size18"
                          v-show="item.groupName"
                        >{{groupName(item)}} </span>
                        <span
                          class="size14"
                          v-show="item.studentNums"
                        >({{item.studentNums }}人)</span>
                      </div>
                    </div>
                    <div class="flex flex-column-center right12">
                      <div>获&nbsp;</div>
                      <div
                        class="number"
                        v-if="currentIndex == 0"
                      >{{item.goodNums || 0}}</div>
                      <div
                        class="number"
                        v-if="currentIndex == 1"
                      >{{item.groupGoodNums || 0}}</div>
                      <div>&nbsp;次表扬</div>
                    </div>
                  </div>
                </template>
                <div class="award-detail">
                  <a-timeline>
                    <a-timeline-item
                      v-for="(item2,index2) in item.goodEvaluationListVOS"
                      :key="index2"
                      :class="{'ative':index2 == 0}"
                    >{{item2.createTime.substr(10)}} - {{item2.teacherName.replace('老师','')}}老师评价【{{habitName(item2.habitName)}}】</a-timeline-item>
                  </a-timeline>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </div>
          <div
            v-else
            class="award-list list empty-list flex-center flex-column-center flex-column"
          >
            <img
              src="@images/home/group_bg.png"
              class="empty-img"
            >
            <span class="top20 size18">{{currentIndex == 0 ? '还没有学生' : '还没有小组'}}</span>

          </div>
        </div>
        <div class="bottom flex flex-center">
          <div class="btn-list flex size14 color10">
            <div
              v-for="(item,index) in menuList"
              :key="index"
              :class="{'active' :currentIndex == index}"
              class="hover flex1 btn-item"
              @click="changeAdward(index)"
            >{{item}}</div>
          </div>
          <div
            class="close-btn flex flex-center flex-column-center"
            @click="onclose()"
          >
            <a-icon type="right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { subText } from '@/utils/common.js'
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    menuIndex: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: null,
    },
    adwardIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    groupName () {
      return function (item) {
        if (item.groupName) {
          return subText(item.groupName, 6)
        }
      }
    },
    habitName () {
      return function (name) {
        if (name) {
          return subText(name, 7)
        }
      }
    }
  },
  data () {
    return {
      closeAnimation: false,
      expandIconPosition: 'right',
      activeKey: 1,
      index: 0,
      form: {
        classId: this.$store.getters.classId,
        type: 0,
      },
      menuList: ['个人排名', '小组排名'],
      currentIndex: this.adwardIndex
    }
  },
  methods: {
    onclose () {
      this.closeAnimation = true
      setTimeout(() => {
        this.$emit('input', false)
        this.closeAnimation = false
      }, 800)
    },
    onpen () {
      this.$emit('input', true)
    },
    changeAdward (index) {
      this.currentIndex = index
      this.$emit('change', index)
    }
  },
}
</script>
<style lang="less" scoped>
.open {
  position: fixed;
  right: 0;
  bottom: calc((100vh - 664px) / 2);
  width: 380px;
  height: 664px;
  z-index: 99999;
  background: #ffce2e;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: 2px solid #ffffff;
  .open-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px;
    .header {
      width: 360px;
      height: 120px;
      border-radius: 20px 20px 0px 0px;
      & img {
        height: 120px;
      }
    }

    .list {
      margin-top: 10px;
      height: 445px;
      overflow: auto;
      padding-bottom: 20px;
      .sort {
        background: rgba(255, 206, 46, 0.3);

        text-align: center;
        line-height: 40px;
      }
      .number {
        width: 30px;
        height: 30px;
        background: #ffce2e;
        border-radius: 22px;
        text-align: center;
        line-height: 30px;
      }
    }

    .icon40 {
      width: 40px;
      height: 40px;
      border-radius: 22px;
    }

    .bottom {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background: #ffde71;
      border-radius: 0px 0px 20px 20px;
      z-index: 99;
      padding: 10px;
      .btn-list {
        width: 160px;
        height: 40px;
        background: #ffce2e;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        border: 1px solid #ffffff;
        .btn-item {
          text-align: center;
          line-height: 40px;
        }
        .btn-item.active {
          background: #ffffff;
          border-radius: 20px;
          opacity: 0.96;
        }
      }
      .close-btn {
        position: absolute;
        left: 10px;
        bottom: 10px;
        width: 40px;
        height: 40px;
        background: #ffce2e;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        border: 1px solid #ffffff;
      }
      .close-btn:hover {
        cursor: pointer;
      }
    }
  }
}

.icon-rank {
  width: 14px;
}

.empty-list {
  background: #ffffff;
  border-radius: 20px;
  opacity: 0.96;
  .empty-img {
    width: 260px;
  }

  & span {
    color: #995f00;
  }
}

/deep/ .award-list .ant-collapse {
  border-radius: 8px !important;
  background-color: transparent !important;
}

/deep/
  .award-list
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 6px 16px !important;
  background-color: rgba(255, 255, 255, 0.96) !important;
  border-radius: 8px !important;
}

/deep/ .award-list .ant-collapse-borderless > .ant-collapse-item {
  margin-bottom: 4px !important;
  border: none !important;
  border-radius: 8px !important;
}

/deep/
  .award-list
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content {
  background: RGBA(255, 228, 152, 1) !important;
  border-radius: 8px !important;
  margin-bottom: 6px;
}
/deep/ .award-list .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

/deep/
  .award-list
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  overflow: hidden;
}

/deep/ .ant-timeline-item {
  height: 50px;
  padding: 0px !important;
}
/deep/ .ant-timeline-item-content {
  height: 50px;
  line-height: 52px;
  font-size: 14px !important;
  color: #000 !important;
  margin-left: 24px !important;
}
/deep/ .ant-timeline-item-tail {
  height: calc(100% - 10px + 68px) !important;
  top: -8px !important;
  left: 7px !important;
  border-color: #fff !important;
}
/deep/ .ant-timeline-item-head-blue {
  top: 12px;
  width: 12px !important;
  height: 12px !important;
  box-sizing: content-box !important;
  background: #ffce2e !important;
  border-radius: 22px !important;
  border: 2px solid #ffffff !important;
}
/deep/ .ant-timeline {
  padding-left: 28px !important;
}

/deep/ .ative .ant-timeline-item-head-blue {
  background-color: rgba(191, 196, 255, 1) !important;
}
</style>
