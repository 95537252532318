<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="540px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        {{ title }}
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <div class="random-content flex-column flex-column-center ">
          <div class="size16 color5">{{ subTitle }}</div>
          <count
            v-if="show"
            :max="max"
            @change="changeNumber($event)"
          ></count>
          <div
            v-else
            class="count top44"
          ></div>
        </div>
        <div class="bottom flex flex-column-center flex-center">
          <close
            @click="close()"
            class="close"
          ></close>
          <div class="submit flex flex-column-center flex-column">
            <my-button
              size="small3"
              class="left12"
              @click="start()"
            >开始抽点
            </my-button>
          </div>
        </div>
      </div>
    </a-modal>
    <random-list
      v-model="showRandomList"
      :title="title"
      :list="randomItems"
      :randomType="type"
      @change="change"
      @again="start()"
      @changeNums="changeNums()"
      @evaluate="evaluate()"
    ></random-list>
    <div
      v-if="showLoading"
      class="loadng-wrap flex"
    >
      <loading></loading>
    </div>
    <evaluate-student-modal
      :studentList="selectedStudent"
      :groupsList="selectedGroup"
      v-model="showEvaluate"
      @refresh="refresh()"
    ></evaluate-student-modal>
  </div>
</template>
<script>
import loading from '@/components/loading/Random.vue'
import MyButton from '@/components/mybutton/MyButton.vue'
import close from '@/components/close/Index.vue'
import count from '@/components/count/Index.vue'
import randomList from './randomList.vue'
import { getRandomNumber } from '@/utils/random.js'
import EvaluateStudentModal from '../evaluate/evaluateStudentModal.vue'
import { mapState } from "vuex";

export default {
  components: {
    randomList,
    MyButton,
    close,
    count,
    loading,
    EvaluateStudentModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null,
    },
    type: {
      type: Number,
      default: 1, //1随机点人 2随机点组，3 每组抽选
    },
  },
  data () {
    return {
      studentInfo: null,
      showRandomList: false,
      showLoading: false,
      randomItems: [],
      randomList: [],
      randomListBak: [],
      showEvaluate: false,
      number: 1,
      groupList: [],
      studentList: [],
      max: 0,
      selectedStudent: [],
      selectedGroup: []
    }
  },
  computed: {
    ...mapState({
      teacherId: state => state.user.userInfo.teacherId,
      courseId: state => state.user.userInfo.courseId
    }),
    show: {
      get () {
        return this.value
      },
      set () {
      },
    },
    myList () {
      return this.list
    },
    title () {
      return this.type == 1
        ? '随机点人'
        : this.type == 2
          ? '随机点组'
          : '每组抽选'
    },
    subTitle () {
      return this.type == 1
        ? '请选择随机点名的学生数'
        : this.type == 2
          ? '请选择随机抽选的小组数'
          : '请选择每个小组抽选的小组成员数'
    },
  },
  watch: {
    show (val) {
      if (val) {
        this.getMaxValue()
        this.number = 1
      }
    },
  },
  methods: {
    getMaxValue () {
      let number = 100
      if (this.type == 3) {
        this.list.map((item) => {
          const { studentEvaluationInfoVOList } = item
          if (studentEvaluationInfoVOList.length < number) {
            number = studentEvaluationInfoVOList.length
          }
        })
        this.max = number
      } else {
        this.max = this.list.length
      }
    },
    close () {
      this.show = false
      this.$emit('input', false)
      this.showRandomList = false
    },
    change (index) {
      if (this.type == 2) {
        this.groupList[index].checked = !this.groupList[index].checked
      } else {
        this.studentList[index].checked = !this.studentList[index].checked
      }
    },
    changeNumber (number) {
      this.number = number
    },
    start () {
      this.close()
      setTimeout(() => {
        this.showLoading = true
      }, 100)
      setTimeout(() => {
        this.getRandomList()
        setTimeout(() => {
          this.showRandomList = true
          this.showLoading = false
        }, 500)
      }, 2000)
    },
    changeNums () {
      this.close();
      this.show = true;
      this.$emit('input', true)
    },
    getRandomList () {
      this.studentList = []
      this.randomItems = []
      this.groupList = []
      if (this.type == 1) {
        this.getRandomStudentList(this.number)
      }

      if (this.type == 2) {
        this.getRandomGroupList()
        this.groupsList = this.randomItems
      }

      if (this.type == 3) {
        this.getEachGroupStudent()
      }
    },
    //每组抽选随机生成学生
    getEachGroupStudent () {
      this.myList.map((item) => {
        const { studentEvaluationInfoVOList } = item
        const studentNumber = getRandomNumber(
          studentEvaluationInfoVOList.length,
          this.number
        )
        studentNumber.map((val) => {
          let itemData = JSON.parse(JSON.stringify(studentEvaluationInfoVOList[val - 1]))
          itemData.name = item.groupName
          itemData.checked = true;

          this.randomItems.push(itemData)
        })
      })
      this.studentList = this.randomItems
    },
    //随机点组
    getRandomGroupList () {
      const list = getRandomNumber(this.myList.length, this.number)
      list.map((item) => {
        const itemData = JSON.parse(JSON.stringify(this.myList[item - 1]))
        itemData.checked = true
        this.randomItems.push(itemData)
      })
      this.groupList = this.randomItems
    },
    //随机点人
    getRandomStudentList (num) {
      this.randomListBak = [];
      this.randomListBak = JSON.parse(JSON.stringify(this.myList));
      const sk = this.teacherId + '-' + this.courseId + '-randomList';
      let list = sessionStorage.getItem(sk) || '[]';
      if (list) {
        this.randomList = JSON.parse(list);
      }
      let flag = false;
      if (num > this.randomList.length) {
        flag = true;
      }
      for (let i = 0; i < num; i++) {
        this.getRandomOne(flag);
      }
      this.studentList = this.randomItems;
      sessionStorage.setItem(sk, JSON.stringify(this.randomList));
    },
    getRandomOne (flag) {
      if (this.randomList.length === 0) {
        this.randomList = JSON.parse(JSON.stringify(this.randomListBak));
      }

      const index = Math.floor(Math.random() * this.randomList.length);

      this.randomItems.push(this.randomList[index]);
      if (flag) {
        this.randomListBak.forEach((item, i) => {
          if (item.studentId === this.randomList[index]['studentId']) {
            this.randomListBak.splice(i, 1);
          }
        })
      }
      this.randomList.splice(index, 1);
    },
    evaluate () {
      this.selectedStudent = this.studentList.filter(item => item.checked)
      this.selectedGroup = this.groupList.filter(item => item.checked)

      if (this.selectedGroup.length == 0 && this.type == 2 || this.selectedStudent.length == 0 && this.type != 2) {
        return this.$message.error('还未选中评价对象！')
      }
      this.showEvaluate = true
    },
    refresh () {
      setTimeout(() => {
        this.$emit('refresh')
      }, 1000)
      this.close()
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  padding-top: 44px;
  padding-bottom: 126px;
}

.count {
  background: rgba(255, 206, 46, 0.3);
  border-radius: 20px;
  width: 270px;
  height: 100px;
}

.close {
  position: absolute;
  left: 30px;
}

.again:hover {
  cursor: pointer;
}

.loadng-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999999999;
}
</style>
