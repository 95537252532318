import router from "@/router";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
    showSpinner: false,
});

const whiteList = ["rank"];

router.beforeEach((to, from, next) => {
    NProgress.start();
    store.commit("UPDATE_NAV", {
        isHide: to.meta.isHide != undefined ? to.meta.isHide : false,
    });
    if (store.getters.token) {
        const user = store.getters.userInfo;
        if (
            (to.name == "login" && user.status == 1) ||
            (to.name == "select" && user.status == 1) ||
            to.name == "rank"
        ) {
            next({
                path: "/",
                replace: true,
            });
        } else if (user.status == 0 && to.name != "select") {
            next({
                path: "/select",
                replace: true,
            });
        } else {
            next();
        }
        NProgress.done();
    } else {
        if (whiteList.indexOf(to.name) !== -1) {
            next();
        } else {
            if (to.name != "login") {
                next({
                    path: "/login",
                    replace: true,
                });
            } else {
                next();
            }
        }
        NProgress.done();
    }
});

router.afterEach(() => {
    NProgress.done(); // finish progress bar
});