<template>
  <div>
    <div
      @click="$emit('select',index)"
      class="student-item flex-column"
    >
      <div
        class="avatar"
        :class="{'active':item.checked}"
      >
        <img
          :src="item.avatar"
          class="avatar-img"
        >
        <div
          v-if="item.checked"
          class="selected flex flex-center flex-column-center"
        >
          <img
            src="@images/home/icon_selected.png"
            class="icon_selected"
          >
        </div>
      </div>
      <div class="student-name size16 color7 div-center">{{name}}</div>
    </div>
  </div>
</template>
<script>
import { subText } from '@/utils/common.js'
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    name () {
      return subText(this.item.studentName, 5)
    },
  },
}
</script>
<style lang="less" scoped>
.student-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  .avatar {
    position: relative;
    .avatar-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: #dfe9ff;
    }
    .selected {
      position: absolute;
      left: 0;
      top: 0;
      width: 60px;
      height: 60px;
      z-index: 8;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      border: 2px solid #fff;
      .icon_selected {
        width: 50px;
      }
    }
  }
  .avatar.active::before {
    content: "";
    position: absolute;
    border: 8px solid #ff8c29;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    left: -8px;
    top: -8px;
  }
  .student-name {
    // width: 80px;
    // height: 30px;
    // background: #ffeecd;
    // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    // border-radius: 60px;
    // border: 2px solid #ffffff;
    z-index: 9;
    margin-top: 10px;
    line-height: 32px;
  }
}
.student-item:hover {
  cursor: pointer;
}
</style>
