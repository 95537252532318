<template>
  <div>
    <div
      @click="select(index)"
      class="item flex-column hover"
    >
      <div
        class="avatar"
        :class="{'active':isSelected}"
      >
        <img
          :src="item.habitIcon || require('@images/home/default_habit.png')"
          class="avatar-img"
        >
        <div
          v-if="isSelected"
          class="selected flex flex-center flex-column-center"
        >
          <img
            src="@images/home/icon_selected.png"
            class="icon_selected"
          >
        </div>
      </div>
      <div class="name size16 color7 div-center top6">{{item.habitName}}</div>
      <div
        class="statistics flex"
        :class="{'bg' :item.habitType == 2}"
      >
        <div class="flex1 div-center size16 color7">{{item.habitType == 1 ? '+' : '-'}}{{item.growthValue || 0}}</div>
        <div class="flex1 div-center size16 color7">{{item.habitType == 1 ? '+' : '-'}}{{item.convertFee || 0}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSelected: {
      get () {
        return this.selected
      },
      set () {
      },
    }
  },
  methods: {
    select () {
      this.isSelected = !this.isSelected
      this.$emit('select', this.index, this.selected)
    },
  },
}
</script>
<style lang="less" scoped>
.item {
  // width: 150px;
  // margin-bottom: 30px;
  height: 150px;
  align-items: center;
  .avatar {
    position: relative;
    .avatar-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid #fff;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
    }
    .selected {
      position: absolute;
      left: 0;
      top: 0;
      width: 60px;
      height: 60px;
      z-index: 8;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      border: 2px solid #fff;
      .icon_selected {
        width: 38px;
      }
    }
  }
  .avatar.active::before {
    content: "";
    position: absolute;
    border: 8px solid #ff8c29;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    left: -8px;
    top: -8px;
  }
  .name {
    z-index: 9;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .statistics {
    width: 86px;
    height: 26px;
    background: #ffeecd;
    border-radius: 100px;
    border: 2px solid #ffffff;
    line-height: 22px;
  }

  .bg {
    background: #ffcdf5 !important;
  }
}
</style>
