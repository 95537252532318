<template>
  <div class="home flex-column">
    <class-name
      :badNums="badNums"
      :goodNums="goodNums"
    ></class-name>
    <student-list
      v-if="showStudentList"
      class="animate__animated animate__fadeIn"
      :index="menuIndex"
      @change="changeStudent"
      @evaluate="evaluate"
    ></student-list>
    <group-list
      :list="groupList"
      v-if="showGroup"
      class="animate__animated animate__fadeIn"
      :index="menuIndex"
      :type="groupType"
      @refresh="refreshGroup"
      @addgroup="addGroup"
      @change="changeGroup"
      @evaluate="evaluate"
    ></group-list>
    <menu-side
      :type="groupType"
      @change="changeMenu"
      @random="random"
      @evaluateStudent="evaluateStudent"
      @countDown="countDown"
      @openRankList="showRank = true"
      @openAdwardList="showAdwardList = true"
      @openKnockout="onOpenKnockout"
      @openPointRace="onOpenPointRace"
      @editGroup="editGroup"
      @exitEdit="groupType = 1"
      @addgroup="addGroup"
      @groupRandom="groupRandom"
      @groupRandoPeople="groupRandoPeople"
    ></menu-side>
    <rank-modal v-model="showRank" />
    <adward-list
      :list="adwardList"
      :menuIndex="menuIndex"
      v-model="showAdwardList"
      @change="changeAdwardList"
    ></adward-list>
    <count-modal v-model="showCount"></count-modal>
    <evaluate-student-modal
      v-model="showEvaluate"
      :studentList="selectedStudent"
      @refresh="refreshData"
      :groupsList="selectedGroupList"
    ></evaluate-student-modal>
    <group-add-modal
      :list="groupList"
      v-model="showAddGroup"
      @refresh="refreshGroup"
    ></group-add-modal>

    <random-modal
      :type="randomType"
      v-model="showRandom"
      :list="randomList"
      @refresh="refreshData"
    ></random-modal>
    <finish-class-modal
      v-model="shoFinishRemind"
      @continue="startCountDown"
    ></finish-class-modal>

    <point-race-modal
      v-model="showPointRace"
      :studentList="selectedStudent"
      :groupList="selectedGroupList"
      @refresh="refreshData"
    ></point-race-modal>

    <knockout-modal
      v-model="showKnockout"
      :studentList="selectedStudent"
      :groupList="selectedGroupList"
      @refresh="refreshData"
    ></knockout-modal>
<!--    <recorder-evaluate  @refresh="refreshData" @evaluate="recordEvaluate"></recorder-evaluate>-->
    <div class="full-screen-text" @click="fullScreen">全屏</div>
  </div>
</template>

<script>
import ClassName from '@/components/class/ClassName.vue'
import MenuSide from '@/components/class/MenuSide.vue'
import StudentList from '../components/class/studentList.vue'
import AdwardList from '@/components/class/AdwardList.vue'
import GroupList from '../components/group/groupList.vue'
import groupAddModal from '../components/group/groupAddModal.vue'
import CountModal from '../components/count/countModal.vue'
import { getRewardRank, getGroupRewardRank, allCardNums } from '@/api/home/class.js'
import { courseCountDown } from '@/utils/countdown.js'
import EvaluateStudentModal from '../components/evaluate/evaluateStudentModal.vue'
import RandomModal from '../components/random/randomModal.vue'
import FinishClassModal from '../components/class/finishClassModal.vue'
import RankModal from "@/view/home/components/rank/rankModal";
import pointRaceModal from '@/view/home/components/race/pointRaceModal'
import knockoutModal from '@/view/home/components/race/knockoutModal'
import RecorderEvaluate from "@/components/class/RecorderEvaluate";
export default {
  components: {
    ClassName,
    MenuSide,
    StudentList,
    AdwardList,
    RankModal,
    GroupList,
    CountModal,
    EvaluateStudentModal,
    groupAddModal,
    RandomModal,
    FinishClassModal,
    pointRaceModal,
    knockoutModal,
    RecorderEvaluate
  },
  data () {
    return {
      menuIndex: 0, //底部菜单索引
      showStudentList: true, //是否显示学生列表
      showGroup: false, //是否显示小组列表
      showRandom: false, //是否显示随机弹窗
      showRank: false, //实力榜
      showAdwardList: false, //榜单
      groupType: 1, //1正常 2//编辑
      showCount: false, //计数
      userInfo: this.$store.getters.userInfo,
      studentList: [], //学生列表
      showEvaluate: false, //是否显示评价
      groupList: [], //组列表
      showAddGroup: false, //是否显示添加小组弹窗
      randomType: 1, //随机类型 1 随机点人 2随机点组 3每组抽选
      randomList: [], //随机数组
      selectedStudent: [], //已被选中的学生列表
      selectedGroupList: [], //已被选中的组列表
      adwardList: [], //榜单列表
      adwardIndex: 0, //榜单切换索引
      shoFinishRemind: false,
      timer: null,
      badNums: 0, //坏习惯统计
      goodNums: 0, //好习惯统计
      showKnockout: false, //淘汰赛
      showPointRace: false //积分赛
    }
  },
  created () {
    this.getAdwardList()
    this.getAllCardNums()
    courseCountDown(this)
  },
  methods: {
    onOpenKnockout () {
      if (this.selectedStudent.length == 0 && this.menuIndex == 1) {
        return this.$message.error('请选择学生！')
      }
      if (this.selectedGroupList.length == 0 && this.menuIndex == 3) {
        return this.$message.error('请选择小组！')
      }
      this.showKnockout = true;
    },
    onOpenPointRace () {
      if (this.selectedStudent.length == 0 && this.menuIndex == 1) {
        return this.$message.error('请选择学生！')
      }
      if (this.selectedGroupList.length == 0 && this.menuIndex == 3) {
        return this.$message.error('请选择小组！')
      }
      this.showPointRace = true;
    },
    getAllCardNums () {
      allCardNums().then(res => {
        this.badNums = res.badNums
        this.goodNums = res.goodNums
      })
    },
    getAdwardList () {
      //个人排行
      if (this.adwardIndex == 0) {
        this.getRewardRank()
      }

      //小组排行
      if (this.adwardIndex == 1) {
        this.getGroupRewardRank()
      }
    },
    getGroupRewardRank () {
      getGroupRewardRank({
        classId: this.userInfo.classId,
        type: 1,
      }).then((res) => {
        this.adwardList = res
      })
    },
    getRewardRank () {
      getRewardRank({
        classId: this.userInfo.classId,
        type: 1,
      }).then((res) => {
        this.adwardList = res
      })
    },
    //评价刷新
    refreshData () {
      this.showStudentList = false
      this.showGroup = false
      this.getAdwardList()
      this.getAllCardNums()
      setTimeout(() => {
        if (this.menuIndex == 0 || this.menuIndex == 1) {
          this.showStudentList = true
        } else {
          this.showGroup = true
        }
      }, 20)
    },
    //单独刷新
    refreshGroup () {
      this.showStudentList = false
      this.showGroup = false
      this.getAdwardList()
      setTimeout(() => {
        this.showGroup = true
      }, 20)
    },
    //单独刷新
    refreshStudentList () {
      this.showGroup = false
      this.showStudentList = false
      setTimeout(() => {
        this.showStudentList = true
      }, 20)
    },
    changeMenu (index) {
      this.menuIndex = index
      this.selectedStudent = [];
      this.selectedGroupList = [];
      switch (index) {
        case 0:
          this.singleSelected()
          break
        case 1:
          this.manySelected()
          break
        case 2:
          this.aGroupSelected()
          break
        case 3:
          this.manyGroupSelected()
          break
      }
    },
    //单人
    singleSelected () {
      this.refreshStudentList()
    },
    //多人
    manySelected () {
      this.refreshStudentList()
    },
    //一组
    aGroupSelected () {
      this.refreshGroup()
    },
    //多组
    manyGroupSelected () {
      this.refreshGroup()
    },
    //随机点人
    random () {
      this.randomType = 1
      this.randomList = JSON.parse(JSON.stringify(this.studentList))
      this.showRandom = true
    },
    //评价学生
    evaluateStudent () {
      this.selectedStudent = this.studentList.filter((item) => item.checked)
      if (this.selectedStudent.length == 0 && this.menuIndex == 1) {
        return this.$message.error('请选择学生！')
      }
      if (this.selectedGroupList.length == 0 && this.menuIndex == 3) {
        return this.$message.error('请选择小组！')
      }
      this.showEvaluate = true
    },
    //倒计时
    countDown () {
      this.showCount = true
    },
    //编辑组
    editGroup () {
      this.groupType = 2
    },
    changeStudent (list) {
      this.studentList = list
      this.selectedStudent = this.studentList.filter((item) => item.checked)
    },
    evaluate () {
      this.showEvaluate = true
    },
    //添加小组
    addGroup () {
      this.showAddGroup = true
    },
    changeGroup (list) {
      this.groupList = list
      this.selectedGroupList = this.groupList.filter((item) => item.checked)
    },
    //随机点组
    groupRandom () {
      if (this.groupList.length == 0) {
        return this.$message.error('请先添加小组！')
      }
      this.randomType = 2
      this.randomList = JSON.parse(JSON.stringify(this.groupList))
      this.showRandom = true
    },
    //每组抽选
    groupRandoPeople () {
      if (this.groupList.length == 0) {
        return this.$message.error('请先添加小组！')
      }
      this.randomType = 3
      this.randomList = JSON.parse(JSON.stringify(this.groupList))
      this.showRandom = true
    },
    //切换排行榜
    changeAdwardList (index) {
      this.adwardIndex = index
      this.getAdwardList()
    },
    startCountDown () {
      courseCountDown(this)
    },
    fullScreen(){
      console.log(1)
      const el = document.documentElement;
      if (document.fullscreenElement) {
        // 如果当前已经在全屏模式下，则退出全屏模式
        document.exitFullscreen();
      } else {
        // 否则启动全屏模式
        el.requestFullscreen();
      }
    },
    recordEvaluate(e){
      console.log(e)
      this.showEvaluate = true;
      this.selectedStudent =  this.studentList.filter(item => e.indexOf(item.studentId) != -1)
    }
  },
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  background: linear-gradient(360deg, #edf4ff 0%, #fff2d2 100%);
  align-items: center;
}
.full-screen-text{
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 20px;
  color: rgba(0,0,0,0.8);
  z-index: 999999;
}

</style>

