import request from '@/utils/request'
import config from '@/settings.js'

export function getUserInfo() {
    return request({
        url: config.requestUrlModel + '/classroom/general/getInfo',
        method: 'get'
    })
}

export function getClass() {
    return request({
        url: config.requestUrlModel + '/classroom/general/classSelect',
        method: 'get'
    })
}

export function classesBegin(params) {
    return request({
        url: config.requestUrlModel + '/classroom/general/classesBegin',
        method: 'get',
        params
    })
}

export function finishClass(data) {
    return request({
        url: config.requestUrlModel + '/classroom/general/finishClass',
        method: 'post',
        data,
        submitOnce: true
    })
}