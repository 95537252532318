<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="740px"
      height="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        添加小组
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <div class="group-content flex-column">
          <div class="flex flex-center">
            <div class="group-name div-center size24">
              <input
                v-model="form.groupName"
                placeholder="请输入小组名称"
                class="name-input"
              />
            </div>
          </div>
          <div class="top20">
            <div v-if="unJoinGroupStudentList.length > 0">
              <div class="size16 color10 div-center">未加入任何小组</div>
              <div class="student-list top24">
                <student-item
                  v-for="(item,index) in unJoinGroupStudentList"
                  :item="item"
                  :key="index"
                  :index="index"
                  @select="selectStudent"
                ></student-item>
              </div>
            </div>
          </div>
          <div class="">
            <div class="line"></div>
          </div>
          <div
            class="top20"
            v-if="joinGroupList"
          >
            <div
              v-for="(item,index) in joinGroupList"
              :key="index"
            >
              <div class="size16 color10 div-center">{{item.groupName}} </div>
              <div class="student-list top24">
                <student-item
                  v-for="(item2,index2) in item.studentEvaluationInfoVOList"
                  :item="item2"
                  :key="index2"
                  :index="index2"
                  @select="selectGroupStudent(item2,item.studentEvaluationInfoVOList,$event)"
                ></student-item>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom flex flex-column-center flex-center">
          <a-popconfirm
            title="点击取消将不保存任何小组信息，确认取消并退出添加小组吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="close"
          >
            <my-button size="small3">取消</my-button>
          </a-popconfirm>
          <my-button
            size="small3"
            class="left20"
            @click="addHandle()"
          >添加小组</my-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import MyButton from '@/components/mybutton/MyButton.vue'
import StudentItem from '../class/studentItem.vue'
import { getUnclassifiedList, addGroup } from '@/api/home/group.js'
import { getAvatar } from '@/utils/random.js'
export default {
  components: { MyButton, StudentItem },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      userInfo: this.$store.getters.userInfo,
      form: {
        groupName: '',
        studentIds: [],
        classId: this.$store.getters.classId,
      },
      unJoinGroupStudentList: [],
      joinGroupList: [],
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.getData()
        this.form.groupName = ''
      }
    },
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () { },
    },
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
    },
    getData () {
      getUnclassifiedList({
        classId: this.userInfo.classId,
      }).then((res) => {
        this.unJoinGroupStudentList = res
        this.joinGroupList = this.list
        this.unJoinGroupStudentList.map((item) => {
          item.avatar = getAvatar(item)
          item.checked = false
          return item
        })
      })
    },
    selectStudent (index) {
      this.$forceUpdate()
      this.unJoinGroupStudentList[index].checked =
        !this.unJoinGroupStudentList[index].checked
    },
    selectGroupStudent (item, list, index) {
      let value = Object.assign({}, item)
      value.checked = !value.checked
      list[index] = value
      this.$forceUpdate()
    },
    addHandle () {
      let [selectedUnJoinGroupStudentList, selectJoinGroupListStudentList] = [
        this.selectedUnJoinGroupStudent(),
        this.selectJoinGroupListStudent(),
      ]
      this.form.studentIds = [
        ...selectedUnJoinGroupStudentList,
        ...selectJoinGroupListStudentList,
      ]
      console.log(this.form.studentIds)
      if (!this.form.groupName) {
        return this.$message.error('请输入小组名称')
      }
      if (this.form.studentIds.length == 0) {
        return this.$message.error('请选择学生')
      }
      addGroup(this.form).then(() => {
        setTimeout(() => {
          this.$message.success(`已创建${this.form.groupName}小组！`)
          this.close()
          this.$emit('refresh')
        }, 800)
      })
    },
    selectedUnJoinGroupStudent () {
      const list = this.unJoinGroupStudentList.filter((item) => item.checked)
      return list.map((item) => item.studentId)
    },
    selectJoinGroupListStudent () {
      let list = []
      this.joinGroupList.map((item) => {
        item.studentEvaluationInfoVOList.map((item2) => {
          if (item2.checked) {
            list.push(item2.studentId)
          }
        })
      })
      return list
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  padding-top: 20px;
  padding-bottom: 80px;
  .group-content {
    width: 100%;
    height: 490px;
    overflow: auto;
    padding-left: 60px;
    padding-right: 60px;
    .group-name {
      width: 600px;
      height: 52px;
      background: rgba(255, 206, 46, 0.3);
      border-radius: 20px;
      line-height: 52px;
    }
  }
  .student-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 120px);
    justify-content: center;
  }
  .dissolve {
    position: absolute;
    bottom: 10px;
    right: 20px;
    padding: 20px;
  }
  .dissolve:hover {
    cursor: pointer;
  }
}
.name-input {
  background-color: transparent;
  text-align: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.line {
  height: 2px;
  background: rgba(255, 206, 46, 0.3);
  border-radius: 20px;
}
</style>
