<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        {{title}}
      </div>
      <div class="content flex-column flex-center flex-column-center">
        <div class="student flex">
          <random-item
            v-for="(item,index) in list"
            :key="index"
            :item="item"
            :index="index"
            :selected="item.checked"
            :showGroupImage="randomType == 2"
            @change="change"
          ></random-item>
        </div>
        <div class="bottom flex flex-column-center flex-space-between">
          <close @click="close()"></close>
          <div class="submit flex flex-column-center">
            <my-button
              size="small2"
              class="left12"
              @click="$emit('again')"
            >{{ randomType === 2 ? '换一下组' : '换一下人'}}</my-button>
            <my-button
              size="small2"
              class="left12"
              @click="evaluate()"
            >发送评价</my-button>
            <div
              class="size16 color6 again"
              @click="$emit('changeNums')"
            >{{ randomType === 2 ? '换组数' : '换人数'}}</div>

          </div>
        </div>
      </div>
      <audio
        v-if="value"
        src="@/assets/audio/home/show.mp3"
        autoplay="autoplay"
      ></audio>
    </a-modal>
  </div>
</template>
<script>
import MyButton from '@/components/mybutton/MyButton.vue'
import close from '@/components/close/Index.vue'
import randomItem from './randomItem.vue'
export default {
  components: { MyButton, close, randomItem },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    randomType: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      studentInfo: null,
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () { },
    },
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
    },
    evaluate () {
      this.$emit('evaluate')
    },
    change (index) {
      this.$emit('change', index)
    }
  },
}
</script>
<style lang="less" scoped>
.header {
  height: 50px;
  line-height: 50px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
}
.content {
  position: relative;
  padding-top: 20px;
  padding-bottom: 80px;
  .student {
    min-height: 160px;
    flex-wrap: wrap;
    max-height: 370px;
    overflow: auto;
    padding: 40px 40px 0 0;
    justify-content: center;
    .avatar {
      width: 120px;
      height: 120px;
      background: #b9e6fa;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 60px;
      border: 6px solid #ffffff;
    }
    .name {
      width: 120px;
      height: 40px;
      background: #ffeecd;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
      border-radius: 60px;
      border: 4px solid #ffffff;
      margin-top: -12px;
      text-align: center;
      line-height: 32px;
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 70px;
    background: rgba(255, 255, 255, 0.96);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    max-height: 80px;
  }
}

.again:hover {
  cursor: pointer;
}
.again {
  margin-left: 20px;
}
</style>