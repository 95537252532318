<template>
  <div>
    <a-modal
      class="mymodal"
      v-model="show"
      :centered="true"
      :closable="false"
      :footer="null"
      width="740px"
      height="640px"
      @cancel="close()"
    >
      <div class="header flex size16 color5 flex-center">
        计时器
      </div>
      <div class="content flex-column  flex-column-center">
        <div class="count-content">
          <div class="flex flex-center">
            <div class="flex nav  size16 color10">
              <div
                class="flex1"
                @click="changeCount(0)"
                :class="{'active':currentIndex === 0}"
              >秒表
              </div>
              <div
                class="flex1"
                @click="changeCount(1)"
                :class="{'active':currentIndex === 1}"
              >倒计时
              </div>
            </div>
          </div>
          <div class="top40">
            <second-chronograph
              v-if="currentIndex === 0"
              @close="close"
            ></second-chronograph>
            <count-down
              v-if="currentIndex === 1"
              @close="close"
            ></count-down>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import secondChronograph from '@/components/count/SecondChronograph.vue'
import CountDown from '../../../../components/count/CountDown.vue'

export default {
  components: { secondChronograph, CountDown },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      currentIndex: 0,
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set () { },
    },
  },
  methods: {
    close () {
      this.show = false
      this.$emit('input', false)
    },
    changeCount (v) {
      this.currentIndex = v
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  position: relative;
  max-height: 490px;
  height: 490px;

  .count-content {
    padding-top: 30px;
    padding-bottom: 120px;

    .nav {
      width: 320px;
      height: 40px;
      background: #f1f7ff;
      box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      text-align: center;
      line-height: 40px;
      overflow: hidden;

      .active {
        background: #ffce2e !important;
        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
      }
    }

    .nav:hover {
      cursor: pointer;
    }
  }
}
</style>