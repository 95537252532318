<template>
  <div>
    <button
      :disabled="isdisabled"
      @click="onclick()"
      :class="[clickClass,sizeClass,colorClass,disabledClass]"
      class="flex flex-center flex-column-center button hover"
    >
      <slot></slot>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small', //min min2  small small2 small3 min-border medium big
    },
    color: {
      type: String,
      default: 'yellow', //yellow pink white
    },
    isdisabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      clickClass: '',
    }
  },
  computed: {
    sizeClass () {
      return this.size + '-button'
    },
    colorClass () {
      return this.color + '-button'
    },
    disabledClass () {
      return this.isdisabled ? 'disabled-button' : ''
    },
  },
  methods: {
    onclick () {
      if (!this.isdisabled) {
        this.clickClass = 'click-style'
        setTimeout(() => {
          this.clickClass = ''
        }, 200)
        this.$emit('click')
      }
    },
  },
}
</script>
