import request from '@/utils/request'
import config from '@/settings.js'

export function getLoginQrCode() {
    return request({
        url: config.requestUrlModel + '/auth/getLoginQrCode',
        method: 'get'
    })
}

export function checkLoginQrCode(params) {
    return request({
        url: config.requestUrlModel + '/auth/checkLoginQrCode',
        method: 'get',
        params,
        hiddenLoading: true
    })
}

export function authCertificate(data) {
    return request({
        url: config.requestUrlModel + '/classroom/general/authCertificate',
        method: 'post',
        data
    })
}
